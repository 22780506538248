import { ImmutableMoment } from '../../../../utils';
import { EnglishDateStrings } from '../../utils/DateStrings';
import { BillListKind } from '../../../../viewmodels';
import { RecurrenceKind } from '../../../../models';

export interface BillsStrings {
  readonly account: string;
  readonly addDialogTitle: string;
  readonly amountLabel: string;
  readonly cancel: string;
  readonly category: string;
  readonly date: string;
  readonly datePickerFormat: string;
  readonly deleteConfirm: string;
  readonly editDialogTitle: string;
  readonly endDate: string;
  readonly every: string;
  readonly expiredTooltip: string;
  readonly isRecurring: string;
  readonly name: string;
  readonly payConfirm: string;
  readonly save: string;

  readonly allBillsEmptyListSubtitle: string;
  readonly allBillsEmptyListTitle: string;
  readonly allBillsListTitle: string;

  readonly upcomingBillsEmptyListSubtitle: string;
  readonly upcomingBillsEmptyListTitle: string;
  readonly upcomingBillsListTitle: string;

  readonly lateBillsEmptyListSubtitle: string;
  readonly lateBillsEmptyListTitle: string;
  readonly lateBillsListTitle: string;

  amount(amount: number): string;
  dueDate(date: ImmutableMoment): string;
  groupingTitle(category: string, totalAmount: number): string;
  itemTitle(name: string, account?: string): string;
  recurrenceKind(kind: RecurrenceKind, interval: number): string;
  shortListTitle(listKind: BillListKind): string;
}

export const EnglishBillsStrings: BillsStrings = {
  account: 'Account',
  addDialogTitle: 'Add bill',
  amountLabel: 'Amount',
  cancel: 'Cancel',
  category: 'Category',
  date: 'Date',
  datePickerFormat: EnglishDateStrings.numericDateFormat,
  deleteConfirm: "Do you really want to delete this bill? This action can't be undone.",
  editDialogTitle: 'Edit bill',
  endDate: 'Until',
  every: 'Every',
  expiredTooltip: 'This bill is expired',
  isRecurring: 'Recurring',
  name: 'Name',
  payConfirm: 'Do you want to mark this bill as paid?',
  save: 'Save',
  allBillsEmptyListSubtitle: 'Add a bill to start getting organized',
  allBillsEmptyListTitle: 'No bills',
  allBillsListTitle: 'Bills',
  upcomingBillsEmptyListSubtitle: 'Enjoy your money!',
  upcomingBillsEmptyListTitle: 'No upcoming bills',
  upcomingBillsListTitle: 'Upcoming bills',
  lateBillsEmptyListSubtitle: 'Good job on keeping up with your bills!',
  lateBillsEmptyListTitle: 'No late bills',
  lateBillsListTitle: 'Late bills',
  amount: (amount) => {
    const formatter = new Intl.NumberFormat('en-CA', {
      currency: 'CAD',
      minimumFractionDigits: 2,
      style: 'currency'
    });
    return formatter.format(amount);
  },
  dueDate: EnglishDateStrings.humanize,
  groupingTitle: (category, totalAmount) => `${category} - ${EnglishBillsStrings.amount(totalAmount)}`,
  itemTitle: (name, account) => (account != null ? `${name} (${account})` : name),
  recurrenceKind: (kind: RecurrenceKind, interval: number) => {
    const plural = interval > 1;

    switch (kind) {
      case 'Daily':
        return 'day' + (plural ? 's' : '');
      case 'Weekly':
        return 'week' + (plural ? 's' : '');
      case 'Monthly':
        return 'month' + (plural ? 's' : '');
      case 'Yearly':
        return 'year' + (plural ? 's' : '');

      default:
        return kind;
    }
  },
  shortListTitle: (listKind) => {
    switch (listKind) {
      case 'all':
        return 'All';
      case 'upcoming':
        return 'Upcoming';
      case 'late':
        return 'Late';
      default:
        return listKind;
    }
  }
};
