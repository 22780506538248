import * as React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../resources/lotties/notfound.json';
import * as MUI from '@material-ui/core';
import { useServices } from '../../hooks';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export interface NotFoundProps {}

export const NotFound: React.FunctionComponent<NotFoundProps> = observer(() => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.onboarding;
  const classes = useStyles();

  return (
    <MUI.Box height={'100%'} width={'100%'} display={'flex'} alignItems={'center'} flexDirection={'column'} padding={2}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData
        }}
      />
      <Link to={'/'} className={classes.link}>
        <MUI.Button color={'primary'} variant={'contained'}>
          {strings.goToHome}
        </MUI.Button>
      </Link>
    </MUI.Box>
  );
});

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    link: {
      ...theme.typography.button,
      textDecoration: 'inherit'
    }
  })
);
