import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { DialogCancelled, DialogResult, ServicesProvider } from '../components';
import { head } from 'lodash';
import { ThemeService } from './ThemeService';
import { ThemeProvider } from '@material-ui/core';

interface ModalElement {
  element: Element;
  result: DialogResult;
}

export class ModalService {
  private _modalStack: ModalElement[] = [];

  constructor(private _theme: ThemeService) {}

  get isDisplayingModal() {
    return this._modalStack.length > 0;
  }

  showModal<TResult, TProps>(Modal: React.ComponentType<TProps>, props: TProps): Promise<TResult | DialogCancelled> {
    const modalWrapper = document.body.appendChild(document.createElement('div'));

    return new Promise((resolve) => {
      const result: DialogResult<TResult> = {
        onSuccess: (value) => {
          this.closeModal(modalWrapper);
          value != null ? resolve(value) : resolve();
        },
        onCancel: () => {
          this.closeModal(modalWrapper);
          resolve('cancelled');
        }
      };

      ReactDOM.render<TProps>(
        <ThemeProvider theme={this._theme.muiTheme}>
          <ServicesProvider>
            <Modal {...props} {...result} />
          </ServicesProvider>
        </ThemeProvider>,
        modalWrapper
      );

      this._modalStack.push({ element: modalWrapper, result });
    });
  }

  closeAllModals() {
    let modal: ModalElement | undefined;

    while ((modal = this._modalStack.pop()) != null) {
      modal.result.onCancel!();
      this.closeModalCore(modal.element);
    }
  }

  private closeModal(modalRef?: any) {
    let modal: ModalElement | undefined;

    if (modalRef != null) {
      // Try to find the modal in the stack
      const index = this._modalStack.findIndex((m) => m.element === modalRef);

      // If found, remove the modal from the stack.
      if (index !== -1) {
        modal = head(this._modalStack.splice(index, 1));
      }
    } else {
      // No modal were pass... pop the last one.
      modal = this._modalStack.pop();
    }

    // If a modal was found, close it.
    if (modal != null) {
      this.closeModalCore(modal.element);
    }
  }

  private closeModalCore(modal: Element) {
    ReactDOM.unmountComponentAtNode(modal);
    modal.remove();
  }
}
