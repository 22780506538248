import { computed, makeObservable } from 'mobx';
import { PeriodContract } from './api';
import { immutableMoment, ImmutableMoment } from '../utils';

export interface Period {
  readonly start: ImmutableMoment;
  readonly end: ImmutableMoment;

  readonly isActive: boolean;
  readonly asContract: PeriodContract;
}

export class AppPeriod implements Period {
  constructor(private readonly _period: PeriodContract) {
    makeObservable(this);
  }

  @computed
  get start(): ImmutableMoment {
    return immutableMoment(this._period.start).startOf('day');
  }

  @computed
  get end(): ImmutableMoment {
    return immutableMoment(this._period.end).endOf('day');
  }

  @computed
  get isActive(): boolean {
    return immutableMoment().isBetween(this.start, this.end, 'day', '[]');
  }

  @computed
  get asContract(): PeriodContract {
    return this._period;
  }
}
