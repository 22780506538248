import { AuthenticationService, NavigationService } from '../../services';

export class LoginViewModel {
  constructor(private _authentication: AuthenticationService, private _navigation: NavigationService) {}

  async login() {
    if (this._authentication.user == null) {
      await this._authentication.login();
    }
    this._navigation.redirectToReferrerOrLanding();
  }
}
