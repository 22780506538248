import { action, computed, IObservableArray, observable, makeObservable } from 'mobx';
import { EditableProperty } from './EditableProperty';
import { flatten, compact } from 'lodash';

export class EditableModel<T> {
  protected fields?: IObservableArray<EditableProperty<any, T>>;

  constructor(private readonly _isNew: boolean = false) {
    makeObservable(this);
    this.setFields([]);
  }

  @computed
  get hasChanges() {
    return this.shouldBeDeleted || this.fields!.some((field) => field.isChanged);
  }

  get shouldBeCreated() {
    return this._isNew;
  }

  @computed
  get shouldBeDeleted(): boolean {
    return !!this.fields?.every((field) => field.value == null);
  }

  @action
  resetChanges(): void {
    this.fields!.forEach((field) => field.reset());
  }

  protected setFields(fields: EditableProperty<any, T>[], editables: EditableModel<any>[] = []) {
    if (this.fields != null && this.fields.length > 0) {
      throw new Error("Invalid operation. Fields should be set once in the derived class' constructor.");
    }

    this.fields = observable.array<EditableProperty<any, T>>(
      compact(flatten(editables.map((editable) => editable.fields))).concat(fields)
    );
  }
}
