import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { EditableBill } from '../../../models';
import { Bill, EditableRecurrenceData } from '../../../models';
import { DataStore } from '../../../services';
import { find, sortBy } from 'lodash';
import { ImmutableMoment } from '../../../utils';

export class BillEditViewModel {
  private readonly _editableBill: EditableBill;
  @observable private _isSaving: boolean = false;

  constructor(
    private readonly _dataStore: DataStore,
    private readonly _billId: string | undefined,
    private _onSuccess: () => void,
    private _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableBill = new EditableBill(this.bill);
  }

  @computed
  get name(): string {
    return this._editableBill.name;
  }

  set name(value: string) {
    this._editableBill.name = value;
  }

  @computed
  get amount(): number | undefined {
    return this._editableBill.amount || undefined;
  }

  set amount(value: number | undefined) {
    this._editableBill.amount = value!;
  }

  @computed
  get account(): string | undefined {
    return this._editableBill.account;
  }

  set account(value: string | undefined) {
    this._editableBill.account = value;
  }

  @computed
  get category(): string | undefined {
    return this._editableBill.category;
  }

  set category(value: string | undefined) {
    this._editableBill.category = value;
  }

  @computed
  get date(): ImmutableMoment {
    return this._editableBill.date;
  }

  set date(value: ImmutableMoment) {
    this._editableBill.date = value;
  }

  get recurrenceData(): EditableRecurrenceData {
    return this._editableBill.recurrenceData;
  }

  @computed
  get accountsList(): string[] {
    return sortBy(this._dataStore.accounts);
  }

  @computed
  get categoriesList(): string[] {
    return sortBy(this._dataStore.categories);
  }

  @computed
  get isSaving(): boolean {
    return this._isSaving;
  }

  @computed
  get canSave(): boolean {
    return (
      !this._isSaving &&
      this._editableBill.hasChanges &&
      this.name.length > 0 &&
      this.amount != null &&
      (this.recurrenceData.kind !== 'Once' ? this.recurrenceData.interval != null : true)
    );
  }

  get shouldBeCreated(): boolean {
    return this._editableBill.shouldBeCreated;
  }

  private get bill(): Bill | undefined {
    return find(this._dataStore.bills, { id: this._billId });
  }

  @action
  async save() {
    this._isSaving = true;
    this.shouldBeCreated
      ? await this._dataStore.addBill(this._editableBill)
      : await this._dataStore.updateBill(this._editableBill);
    this._onSuccess();
    runInAction(() => (this._isSaving = false));
  }

  cancel() {
    if (!this.isSaving) {
      this._onCancel();
    }
  }
}
