import { EditableModel } from './EditableModel';
import { RecurrenceData } from '../RecurrenceData';
import { computed, makeObservable } from 'mobx';
import { EditableProperty } from './EditableProperty';
import { immutableMoment, ImmutableMoment } from '../../utils';
import { unitOfTime } from 'moment';
import { RecurrenceKind } from '../api';

export class EditableRecurrenceData extends EditableModel<RecurrenceData> implements RecurrenceData {
  private _date: EditableProperty<ImmutableMoment, RecurrenceData>;
  private _kind: EditableProperty<RecurrenceKind, RecurrenceData>;
  private _interval: EditableProperty<number, RecurrenceData>;
  private _endDate: EditableProperty<ImmutableMoment, RecurrenceData>;

  constructor(private readonly _recurrenceData: RecurrenceData | undefined) {
    super(_recurrenceData == null);
    makeObservable(this);

    this.setFields([
      (this._date = new EditableProperty<ImmutableMoment, RecurrenceData>(
        _recurrenceData?.date ?? immutableMoment(),
        (value1, value2) => this.areSameDates(value1, value2, 'day')
      )),
      (this._kind = new EditableProperty<RecurrenceKind, RecurrenceData>(_recurrenceData?.kind ?? 'Once')),
      (this._interval = new EditableProperty<number, RecurrenceData>(_recurrenceData?.interval)),
      (this._endDate = new EditableProperty<ImmutableMoment, RecurrenceData>(
        _recurrenceData?.endDate,
        (value1, value2) => this.areSameDates(value1, value2, 'day')
      ))
    ]);
  }

  @computed
  get date(): ImmutableMoment {
    return this._date.value!;
  }

  set date(value: ImmutableMoment) {
    this._date.value = value;
  }

  @computed
  get kind(): RecurrenceKind {
    return this._kind.value!;
  }

  set kind(value: RecurrenceKind) {
    this._kind.value = value;

    if (value === 'Once') {
      this.interval = undefined;
    } else if (this.interval == null) {
      this.interval = 1;
    }
  }

  @computed
  get interval(): number | undefined {
    return this._interval.value;
  }

  set interval(value: number | undefined) {
    this._interval.value = value;
  }

  @computed
  get endDate(): ImmutableMoment | undefined {
    return this._endDate.value;
  }

  set endDate(value: ImmutableMoment | undefined) {
    this._endDate.value = value;
  }

  private areSameDates = (
    date1?: ImmutableMoment,
    date2?: ImmutableMoment,
    granularity?: unitOfTime.StartOf
  ): boolean => {
    if (date1 == null && date2 == null) {
      return true;
    } else if (date1 == null || date2 == null) {
      return false;
    }
    return date1.isSame(date2, granularity);
  };
}
