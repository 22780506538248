import { BillContract, PeriodContract, SettingsContract } from '../models';
import { AxiosResponse, default as axios } from 'axios';
import { AuthenticationService } from './AuthenticationService';
import { EnvironmentService } from './EnvironmentService';

export interface RequestHeaders {
  Authorization?: string;
}

export class TransportService {
  constructor(private _authentication: AuthenticationService, private _environment: EnvironmentService) {}

  private get requestUrl(): string {
    return this._environment.apiEndpoint;
  }

  async getPeriod(): Promise<PeriodContract | undefined> {
    const response = await this.sendRequest<PeriodContract | undefined>('/period');
    return response.data;
  }

  async updatePeriod(period: PeriodContract): Promise<void> {
    await this.sendRequest<void>(`/period/update`, { period: JSON.stringify(period) });
  }

  async getBills(): Promise<BillContract[]> {
    const response = await this.sendRequest<BillContract[]>('/bills');
    return response.data;
  }

  async addBill(bill: BillContract): Promise<BillContract> {
    const response = await this.sendRequest<BillContract>('/bills/add', { bill: JSON.stringify(bill) });
    return response.data;
  }

  async updateBill(bill: BillContract): Promise<void> {
    await this.sendRequest<void>('/bills/update', { bill: JSON.stringify(bill) });
  }

  async deleteBill(billId: string): Promise<void> {
    await this.sendRequest('/bills/delete', { billId });
  }

  async getSettings(): Promise<SettingsContract | undefined> {
    const response = await this.sendRequest<SettingsContract | undefined>('/settings');
    return response.data;
  }

  async updateSettings(settings: SettingsContract): Promise<void> {
    await this.sendRequest<void>(`/settings/update`, { settings: JSON.stringify(settings) });
  }

  private async getHeaders(): Promise<RequestHeaders> {
    if (this._authentication.user != null) {
      const token = await this.getToken();
      return {
        Authorization: 'Bearer ' + token
      };
    }
    return {};
  }

  private async getToken(): Promise<string> {
    return this._authentication.user!.getIdToken();
  }

  private async sendRequest<T>(relativeUrl: string, data?: any): Promise<AxiosResponse<T>> {
    return axios.post(this.requestUrl + relativeUrl, data, { headers: await this.getHeaders() });
  }
}
