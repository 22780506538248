import { AppBarStrings, EnglishAppBarStrings } from './AppBarStrings';
import { EnglishProfileMenuStrings, ProfileMenuStrings } from './ProfileMenuStrings';

export interface MainStrings {
  readonly appBar: AppBarStrings;
  readonly profileMenu: ProfileMenuStrings;
}

export const EnglishMainStrings: MainStrings = {
  appBar: EnglishAppBarStrings,
  profileMenu: EnglishProfileMenuStrings
};
