import { DashboardStrings, EnglishDashboardStrings } from './dashboard/DashboardStrings';
import { EnglishMainStrings, MainStrings } from './main/MainStrings';
import { BillsStrings, EnglishBillsStrings } from './lists/BillsStrings';
import { EnglishUtilsStrings, UtilsStrings } from './utils/UtilsStrings';
import { EnglishSettingsStrings, SettingsStrings } from './settings/SettingsStrings';
import { EnglishErrorStrings, ErrorStrings } from './errors/ErrorStrings';

export interface AuthenticatedStrings {
  readonly dashboard: DashboardStrings;
  readonly bills: BillsStrings;
  readonly main: MainStrings;
  readonly settings: SettingsStrings;
  readonly utils: UtilsStrings;
  readonly errors: ErrorStrings;
}

export const EnglishAuthenticatedStrings: AuthenticatedStrings = {
  dashboard: EnglishDashboardStrings,
  bills: EnglishBillsStrings,
  main: EnglishMainStrings,
  settings: EnglishSettingsStrings,
  utils: EnglishUtilsStrings,
  errors: EnglishErrorStrings
};
