import * as React from 'react';
import { DialogResult, ListGroupingField, ResponsiveDialog } from '../../components';
import { useServices, useViewModel } from '../../hooks';
import { observer } from 'mobx-react';
import * as MUI from '@material-ui/core';

export interface SettingsProps extends DialogResult<void> {}

export const Settings: React.FunctionComponent<SettingsProps> = observer(({ onSuccess, onCancel }) => {
  const { viewModels, localization } = useServices();
  const viewModel = useViewModel(() => viewModels.createSettingsViewModel(onSuccess!, onCancel!));
  const strings = localization.localizedStrings.authenticated.settings;
  const classes = useStyles();

  return (
    <ResponsiveDialog
      open
      maxWidth={'sm'}
      fullWidth
      onClose={() => viewModel.cancel()}
      title={strings.dialogTitle}
      canSave={viewModel.canSave}
      canClose={!viewModel.isSaving}
      onSave={() => viewModel.save()}
    >
      <MUI.DialogContent>
        <MUI.Box display={'flex'} flexDirection={'column'}>
          {viewModel.isSaving ? (
            <MUI.CircularProgress className={classes.spinner} />
          ) : (
            <MUI.Box>
              <ListGroupingField viewModel={viewModel.grouping} className={classes.field} />
            </MUI.Box>
          )}
        </MUI.Box>
      </MUI.DialogContent>
      <MUI.DialogActions>
        <MUI.Button disabled={!viewModel.canSave} color={'primary'} onClick={() => viewModel.save()}>
          {strings.save}
        </MUI.Button>
        <MUI.Button disabled={viewModel.isSaving} onClick={() => viewModel.cancel()}>
          {strings.cancel}
        </MUI.Button>
      </MUI.DialogActions>
    </ResponsiveDialog>
  );
});

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    spinner: {
      margin: 'auto'
    },
    field: {
      marginTop: theme.spacing(1)
    }
  })
);
