import { Bill } from '../models';
import { getRecurringMoments } from './ImmutableMoment';
import { flatten } from 'lodash';
import { Range } from './ImmutableMoment';

export function getRecurringBills(bills: Bill[], range: Range): Bill[] {
  return flatten(
    bills.map((bill) =>
      getRecurringMoments(range, bill.recurrenceData).map((date) =>
        bill.clone({
          recurrenceData: {
            date: date.local(true).toISOString()
          }
        })
      )
    )
  );
}
