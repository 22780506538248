import * as React from 'react';
import * as MUI from '@material-ui/core';
import ConfirmIcon from '@material-ui/icons/DoneRounded';
import CancelIcon from '@material-ui/icons/CloseRounded';
import { IconButton, useTheme } from '@material-ui/core';

export interface SwipeableListItemActionConfirmProps {
  /**
   * The confirmation message.
   */
  message: string;
  /**
   * The background color. Default is white.
   */
  backgroundColor?: string;
  className?: string;
  style?: React.CSSProperties;

  /**
   * Callback on confirm
   */
  onConfirm: () => void;
  /*8
  Callback on cancel
   */
  onCancel: () => void;
}

export const SwipeableListItemActionConfirm: React.FunctionComponent<SwipeableListItemActionConfirmProps> = (props) => {
  const theme = useTheme();
  const { className, style, backgroundColor = theme.palette.common.white, message, onConfirm, onCancel } = props;
  const classes = useStyles({ backgroundColor });

  return (
    <MUI.ListItem className={className} style={style} classes={{ container: classes.container, root: classes.root }}>
      <MUI.Box display="flex" height="100%" alignItems={'center'}>
        <MUI.ListItemText primary={message} className={classes.text} />
        <MUI.Box display="flex" alignItems="flexEnd">
          <IconButton onClick={onConfirm} className={classes.icon}>
            <ConfirmIcon />
          </IconButton>

          <IconButton onClick={onCancel} className={classes.icon}>
            <CancelIcon />
          </IconButton>
        </MUI.Box>
      </MUI.Box>
    </MUI.ListItem>
  );
};

type StylesKeys = 'listItem' | 'root' | 'container' | 'text' | 'icon';

interface StylesProps {
  backgroundColor: string;
}

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles<StylesKeys, StylesProps>({
    listItem: {
      backgroundColor: ({ backgroundColor }) => backgroundColor
    },
    root: {
      height: '100%'
    },
    container: {
      listStyle: 'none',
      height: '100%',
      display: 'flex'
    },
    text: {
      color: ({ backgroundColor }) => theme.palette.getContrastText(backgroundColor)
    },
    icon: {
      color: ({ backgroundColor }) => theme.palette.getContrastText(backgroundColor),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1)
      }
    }
  })
);
