import * as React from 'react';
import * as MUI from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { ListItem } from '@material-ui/core';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { ListItemTypeMap } from '@material-ui/core/ListItem/ListItem';
import { onWheelStop } from '../../utils';
import { SwipeableListItemActionConfirm } from './SwipeableListItemActionConfirm';
import { useServices } from '../../hooks';
import { observer } from 'mobx-react';

interface Action {
  /**
   * The action icon.
   */
  icon: React.ReactNode;
  /**
   * The action background color.
   */
  color: string;

  /**
   * Indicate to show a confirmation when swiping. Default is false.
   */
  confirm?: boolean;
  /**
   * The confirmation message. Default is `localizedStrings.authenticated.utils.confirm`
   */
  confirmMessage?: string;

  /**
   * Callback when swiping.
   * If `confirm` is `true`, will be called after confirmation and won't be called if cancelled.
   */
  onSwiped: () => void;
}

export interface SwipeableListItemProps {
  /**
   * Left action. Optional.
   */
  leftAction?: Action;
  /**
   * Right action. Optional.
   */
  rightAction?: Action;
  /**
   * The thresold to consider the list item as swiped. Value between `0` and `1`. Default is `0.3`
   */
  threshold?: number;

  children: (props: Partial<ExtendButtonBase<ListItemTypeMap<{ button: true }, 'div'>>>) => React.ReactNode;
}

interface SwipeableListItemState {
  wrapperMaxHeight: number;
  diff: number;
  dragged: boolean;
  dragStartX: number;
  side: 'left' | 'right';
  startTime: number;
  isScrolling: boolean;
  isTouch: boolean;
  actionConfirm: Action | undefined;
  isConfirmAnimating: boolean;
}

export const SwipeableListItem: React.FunctionComponent<SwipeableListItemProps> = observer(
  ({ children, threshold = 0.3, rightAction, leftAction }) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.authenticated.utils;

    const [state, setState] = useState<SwipeableListItemState>({
      wrapperMaxHeight: 1000,
      diff: 0,
      dragged: false,
      dragStartX: 0,
      side: 'left',
      startTime: 0,
      isScrolling: false,
      isTouch: false,
      actionConfirm: undefined,
      isConfirmAnimating: false
    });
    const [forceEnd, setForceEnd] = useState<boolean>(false);
    const { diff, dragged, dragStartX, side, wrapperMaxHeight, isScrolling, actionConfirm, isConfirmAnimating } = state;

    const classes = useStyles();
    const listElementEl = useRef<HTMLLIElement>(null);

    // No choice to do that, otherwise the default state was used instead of the actual state in onDragEndTouch
    useEffect(
      () => {
        if (forceEnd) {
          setForceEnd(false);
          onDragEndTouch();
        }
      },
      // eslint-disable-next-line
      [forceEnd]
    );

    function onDragStartTouch(event: React.TouchEvent) {
      const touch = event.touches[0];
      setState((prevState) => ({
        ...prevState,
        dragged: true,
        dragStartX: touch.clientX,
        startTime: Date.now(),
        isTouch: true
      }));
    }

    function onDragEndTouch() {
      setState((prevState) => ({
        ...prevState,
        dragged: false
      }));
      if (listElementEl.current && diff < listElementEl.current.offsetWidth * threshold * -1) {
        setState((prevState) => ({
          ...prevState,
          diff: listElementEl.current ? -(listElementEl.current.offsetWidth * 2) : 0
        }));
      } else if (listElementEl.current && diff > listElementEl.current.offsetWidth * threshold) {
        setState((prevState) => ({
          ...prevState,
          diff: listElementEl.current ? listElementEl.current.offsetWidth * 2 : 0
        }));
      } else {
        setState((prevState) => ({ ...prevState, diff: 0 }));
      }
    }

    function onTouchMove(event: React.TouchEvent) {
      const touch = event.touches[0];
      const newDiff = touch.clientX - dragStartX;
      if (newDiff < 0) {
        setState((prevState) => ({
          ...prevState,
          diff: newDiff,
          side: `left`
        }));
      } else if (newDiff > 0) {
        setState((prevState) => ({
          ...prevState,
          diff: newDiff,
          side: `right`
        }));
      }
    }

    function onTransitionEnd(event: React.TransitionEvent) {
      event.persist();
      if (
        side === `left` &&
        !dragged &&
        listElementEl.current &&
        diff < listElementEl.current.offsetWidth * threshold * -1
      ) {
        if (rightAction?.confirm) {
          setState((prevState) => ({ ...prevState, actionConfirm: rightAction }));
        } else {
          rightAction?.onSwiped();
        }
        setState((prevState) => ({
          ...prevState,
          diff: 0,
          isTouch: false
        }));
      } else if (
        side === `right` &&
        !dragged &&
        listElementEl.current &&
        diff > listElementEl.current.offsetWidth * threshold
      ) {
        if (leftAction?.confirm) {
          setState((prevState) => ({ ...prevState, actionConfirm: leftAction }));
        } else {
          leftAction?.onSwiped();
        }
        setState((prevState) => ({
          ...prevState,
          diff: 0,
          isTouch: false
        }));
      }
    }

    function onWheel(event: React.WheelEvent) {
      const isHorizontal = event.deltaY === 0;
      if (isHorizontal) {
        event.persist();
        const isLeft = event.deltaX > 0;

        let newDiff = event.clientX - dragStartX;
        if (event.clientX > listElementEl.current!.clientWidth) {
          newDiff = event.clientX - listElementEl.current!.clientWidth - dragStartX;
        }

        // Is first wheel event
        if (!isScrolling) {
          onWheelStop().then(() => {
            setForceEnd(true);
            setState((prevState) => ({ ...prevState, isScrolling: false }));
          });
          setState((prevState) => ({ ...prevState, isScrolling: true }));
        }

        setState((prevState) => ({
          ...prevState,
          dragged: true,
          dragStartX: isLeft ? 0 : listElementEl.current!.clientWidth,
          startTime: Date.now(),
          diff: newDiff,
          side: isLeft ? 'right' : 'left'
        }));
      }
    }

    const getOpacity = (): number => {
      const opacity = parseFloat((Math.abs(diff) / 100).toFixed(2));
      return opacity < 1 ? opacity : 1;
    };

    return (
      <>
        <div
          style={{
            width: actionConfirm ? '100%' : '0',
            backgroundColor: side === `left` ? rightAction?.color : leftAction?.color,
            height: actionConfirm || isConfirmAnimating ? '100%' : 0,
            position: 'relative',
            left: !actionConfirm && side === 'left' ? listElementEl.current?.clientWidth : 0,
            transition: `${side === 'left' && !isConfirmAnimating ? 'left' : 'width'} 0.3s ease-out`
          }}
          onTransitionEnd={() =>
            !actionConfirm &&
            setState((prevState) => ({
              ...prevState,
              isConfirmAnimating: false
            }))
          }
        >
          <SwipeableListItemActionConfirm
            message={actionConfirm?.confirmMessage ?? strings.confirm}
            backgroundColor={side === `left` ? rightAction?.color : leftAction?.color}
            onConfirm={() => {
              actionConfirm?.onSwiped();
              setState((prevState) => ({ ...prevState, actionConfirm: undefined, isConfirmAnimating: true }));
            }}
            onCancel={() =>
              setState((prevState) => ({ ...prevState, actionConfirm: undefined, isConfirmAnimating: true }))
            }
            style={{
              display: actionConfirm ? 'inherit' : 'none'
            }}
          />
        </div>
        {!actionConfirm && (
          <div
            className={classes.wrapperClass}
            data-testid="wrapper-list-item"
            onTransitionEnd={onTransitionEnd}
            style={{
              maxHeight: wrapperMaxHeight
            }}
          >
            <ListItem
              className={classes.backgroundClass}
              data-testid="action-list-item"
              divider={dragged}
              style={{
                backgroundColor: side === `left` ? rightAction?.color : leftAction?.color,
                justifyContent: side === `left` ? `flex-end` : `flex-start`,
                opacity: getOpacity()
              }}
            >
              {side === `left` ? rightAction?.icon : leftAction?.icon}
            </ListItem>
            {children({
              className: classes.listItemClass,
              'data-testid': 'draggable-list-item',
              divider: dragged,
              onTouchStart: onDragStartTouch,
              onTouchMove,
              onTouchEnd: onDragEndTouch,
              onWheel,
              ref: listElementEl,
              style: {
                transform: `translateX(${diff}px)`
              }
            })}
          </div>
        )}
      </>
    );
  }
);

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    backgroundClass: {
      position: `absolute`,
      width: `100%`,
      height: `100%`,
      zIndex: -1,
      display: `flex`,
      flexDirection: `row`,
      justifyContent: `space-between`,
      alignItems: `center`,
      paddingRight: 16,
      color: `#fff`,
      boxSizing: `border-box`
    },
    listItemClass: {
      backgroundColor: `#fff`,
      transition: `transform 0.3s ease-out`
    },
    wrapperClass: {
      position: `relative`,
      transition: `max-height 0.5s ease`,
      transformOrigin: `top`,
      overflow: `hidden`,
      width: `100%`
    }
  })
);
