import { action, computed, makeObservable } from 'mobx';
import { EnglishLocalizedStrings, LocalizedStrings } from '../resources';

export type Locale = 'en';

export const AllLocales: Locale[] = ['en'];

export class LocalizationService {
  private _defaultLocale: Locale = this.normalizeLocale(window.navigator.language, 'en');

  constructor() {
    makeObservable(this);
  }

  @computed
  get currentLocale(): Locale {
    return this._defaultLocale;
  }

  set currentLocale(locale: Locale) {
    this._defaultLocale = locale;
  }

  @computed
  get localizedStrings(): LocalizedStrings {
    return this.resolveStrings(this.currentLocale);
  }

  @action
  setCurrentLocale(locale: string) {
    this.currentLocale = this.normalizeLocale(locale, this.currentLocale);
  }

  private normalizeLocale(locale: string, defaultLocale: Locale): Locale {
    const normalizedLocale = locale.substr(0, 2).toLowerCase() as Locale;

    if (AllLocales.includes(normalizedLocale)) {
      return normalizedLocale;
    }

    console.warn(`Unsupported locale "${normalizedLocale}". Using default value "${defaultLocale}".`);
    return defaultLocale;
  }

  private resolveStrings(locale: Locale): LocalizedStrings {
    switch (locale) {
      case 'en':
        return EnglishLocalizedStrings;

      default:
        throw new Error(`Unsupported locale "${locale}"`);
    }
  }
}
