import * as React from 'react';
import * as MUI from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';

export interface PopperProps extends Omit<MUI.PopperProps, 'anchorEl'> {
  renderPopper: () => React.ReactNode;
}

export const Popper: React.FunctionComponent<PopperProps> = (props) => {
  const { children, renderPopper, open } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    if (anchorEl == null && ref.current != null) {
      setAnchorEl(ref.current);
    }
  }, [anchorEl]);

  const id = open ? 'popper' : undefined;

  return (
    <>
      <div ref={ref} aria-describedby={id}>
        {children}
      </div>
      <MUI.Popper {...props} anchorEl={ref.current} id={id} className={classes.popper}>
        <MUI.Fade in>
          <MUI.Box>{renderPopper()}</MUI.Box>
        </MUI.Fade>
      </MUI.Popper>
    </>
  );
};

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    popper: {
      zIndex: 9999
    }
  })
);
