import * as React from 'react';
import * as MUI from '@material-ui/core';
import { useState } from 'react';

export interface SplashScreenProps {
  /**
   * Indicates to hide the splash screen
   */
  isHidden: boolean;
  className?: string;
}

export const SplashScreen: React.FunctionComponent<SplashScreenProps> = ({ children, className, isHidden }) => {
  const [isTransitionEnded, setIsTransitionEnded] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <MUI.Box
      className={`${classes.container} ${className}`}
      style={{
        opacity: isHidden ? 0 : 1,
        display: isTransitionEnded ? 'none' : 'default'
      }}
      onTransitionEnd={() => setIsTransitionEnded(true)}
    >
      {children}
    </MUI.Box>
  );
};

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    container: {
      transition: `opacity 0.3s ease-out`,
      height: '100%',
      width: '100%',
      position: 'absolute',
      zIndex: 9999
    }
  })
);
