import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { EditableListGrouping, EditableSettings } from '../../../models';
import { DataStore } from '../../../services';

export class SettingsViewModel {
  private readonly _editableSettings: EditableSettings;
  @observable private _isSaving: boolean = false;

  constructor(private _dataStore: DataStore, private _onSuccess: () => void, private _onCancel: () => void) {
    makeObservable(this);
    this._editableSettings = new EditableSettings(_dataStore.settings);
  }

  get grouping(): EditableListGrouping {
    return this._editableSettings.grouping;
  }

  @computed
  get canSave(): boolean {
    return !this.isSaving && this._editableSettings.hasChanges;
  }

  @computed
  get isSaving(): boolean {
    return this._isSaving;
  }

  @action
  async save() {
    this._isSaving = true;
    await this._dataStore.updateSettings(this._editableSettings);
    this._onSuccess();
    runInAction(() => (this._isSaving = false));
  }

  @action
  cancel() {
    if (!this.isSaving) {
      this._onCancel();
    }
  }
}
