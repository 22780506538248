import * as React from 'react';
import * as MUI from '@material-ui/core';
import { observer } from 'mobx-react';

export interface ListHeaderProps {
  className?: string;
  /**
   * Header's title.
   */
  title: string;
  /**
   * Header's subtitle.
   */
  subtitle?: string;
}

export const ListHeader: React.FunctionComponent<ListHeaderProps> = observer(({ className, title, subtitle }) => (
  <MUI.Box className={className} display="flex" flexDirection="column">
    <MUI.Typography variant="h4" color="textPrimary">
      {title}
    </MUI.Typography>
    <MUI.Typography variant="body1" color="primary">
      {subtitle}
    </MUI.Typography>
  </MUI.Box>
));
