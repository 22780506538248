import { BillListKind } from '../../../../viewmodels';

export interface SettingsStrings {
  readonly cancel: string;
  readonly dialogTitle: string;
  readonly groupingGroupLabel: string;
  readonly save: string;

  listLabel(kind: BillListKind): string;
}

export const EnglishSettingsStrings: SettingsStrings = {
  cancel: 'Cancel',
  dialogTitle: 'Settings',
  groupingGroupLabel: 'Select the lists you want to enable grouping by category',
  save: 'Save',

  listLabel: (kind) => {
    switch (kind) {
      case 'late':
        return 'Late bills list';
      case 'upcoming':
        return 'Upcoming bills list';
      case 'all':
        return 'All bills list';
      default:
        return `${kind} bills list`;
    }
  }
};
