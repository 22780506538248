import { RouteService } from './RouteService';
import { History } from 'history';
import { RouteTemplates } from '../Routes';
import { DialogCancelled, EditBill, EditPeriod } from '../components';
import { ModalService } from './ModalService';
import { Period } from '../models';
import { Settings } from '../views';

export class NavigationService {
  constructor(
    private readonly _history: History,
    private readonly _route: RouteService,
    private _modal: ModalService
  ) {}

  /**
   * Redirect to the referrer or the landing page
   * @param referrer The referrer where to redirect. Optional.
   */
  redirectToReferrerOrLanding(referrer?: string) {
    if (referrer != null) {
      this._history.replace(referrer);
    } else {
      this._history.replace(this._route.getReferrer() || this._route.resolveLocation(RouteTemplates.landing));
    }
  }

  navigateToLanding() {
    this.navigateToLocation(RouteTemplates.landing);
  }

  navigateToLogout() {
    this.navigateToLocation(RouteTemplates.logout);
  }

  async navigateToPeriodEdit(period: Period | undefined): Promise<void | DialogCancelled> {
    return this._modal.showModal(EditPeriod, { period });
  }

  async navigateToBillEdit(billId?: string): Promise<void | DialogCancelled> {
    return this._modal.showModal(EditBill, { billId });
  }

  async navigateToSettings(): Promise<void | DialogCancelled> {
    return this._modal.showModal(Settings, {});
  }

  private navigateToLocation(routeTemplate: string) {
    const location = this._route.resolveLocation(routeTemplate);
    this._history.push(location);
  }
}
