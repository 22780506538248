export interface ErrorStrings {
  readonly loadAll: string;

  readonly addBill: (e: Error) => string;
  readonly deleteBill: (e: Error) => string;
  readonly getBills: (e: Error) => string;
  readonly getPeriod: (e: Error) => string;
  readonly getSettings: (e: Error) => string;
  readonly updateBill: (e: Error) => string;
  readonly updatePeriod: (e: Error) => string;
  readonly updateSettings: (e: Error) => string;
}

export const EnglishErrorStrings: ErrorStrings = {
  loadAll: 'An error occurred while loading the user data.',

  addBill: (e) => `An error occurred while adding a bill. Details: ${e.message}`,
  deleteBill: (e) => `An error occurred while deleting a bill. Details: ${e.message}`,
  getBills: (e) => `An error occurred while loading bills. Details: ${e.message}`,
  getPeriod: (e) => `An error occurred while loading the period. Details: ${e.message}`,
  getSettings: (e) => `An error occurred while loading the user settings. Details: ${e.message}`,
  updateBill: (e) => `An error occurred while updating a bill. Details: ${e.message}`,
  updatePeriod: (e) => `An error occurred while updating the period. Details: ${e.message}`,
  updateSettings: (e) => `An error occurred while updating the user settings. Details: ${e.message}`
};
