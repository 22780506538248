import { AuthenticatedStrings, EnglishAuthenticatedStrings } from './authenticated/AuthenticatedStrings';
import { EnglishOnboardingStrings, OnboardingStrings } from './onboarding/OnboardingStrings';

export interface LocalizedStrings {
  readonly authenticated: AuthenticatedStrings;
  readonly onboarding: OnboardingStrings;
}

export const EnglishLocalizedStrings: LocalizedStrings = {
  authenticated: EnglishAuthenticatedStrings,
  onboarding: EnglishOnboardingStrings
};
