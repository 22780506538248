import * as React from 'react';
import * as MUI from '@material-ui/core';
import Lottie from 'react-lottie';
import animationData from '../../resources/lotties/loading.json';

export interface LoadingProps {
  className?: string;
}

export const Loading: React.FunctionComponent<LoadingProps> = ({ className }) => (
  <MUI.Box height={'100%'} width={'100%'} display={'flex'} alignItems={'center'} className={className}>
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }}
      height={400}
      width={400}
    />
  </MUI.Box>
);
