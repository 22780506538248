import { BillContract, PartialBillContract } from './api';
import { AppRecurrenceData, RecurrenceData } from './RecurrenceData';
import { ImmutableMoment } from '../utils';
import { merge, cloneDeep } from 'lodash';

export interface Bill {
  readonly id: string;
  readonly amount: number;
  readonly name: string;
  readonly date: ImmutableMoment;
  readonly account?: string;
  readonly category?: string;
  readonly recurrenceData: RecurrenceData;
  readonly isExpired: boolean;

  readonly asContract: BillContract;

  clone(bill?: PartialBillContract): Bill;
}

export class AppBill implements Bill {
  constructor(private readonly _bill: BillContract) {}

  get id(): string {
    return this._bill.id;
  }

  get amount(): number {
    return parseFloat(this._bill.amount.toString());
  }

  get name(): string {
    return this._bill.name;
  }

  get date(): ImmutableMoment {
    return this.recurrenceData.date;
  }

  get account(): string | undefined {
    return this._bill.account;
  }

  get category(): string | undefined {
    return this._bill.category;
  }

  get recurrenceData(): RecurrenceData {
    return new AppRecurrenceData(this._bill.recurrenceData);
  }

  get isExpired(): boolean {
    if (this.recurrenceData.endDate == null) {
      return false;
    }
    return this.recurrenceData.endDate.isBefore(this.date, 'day');
  }

  get asContract(): BillContract {
    return this._bill;
  }

  clone(bill?: PartialBillContract): Bill {
    return new AppBill(merge(cloneDeep(this._bill), bill));
  }
}
