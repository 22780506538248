import { ListSection } from '../../../components';
import { BillItemViewModel } from './BillItemViewModel';
import { LocalizationService } from '../../../services';
import { computed } from 'mobx';
import { sum } from 'lodash';

export interface BillSectionViewModel extends ListSection {
  items: BillItemViewModel[];
}

export class AppBillSectionViewModel implements BillSectionViewModel {
  constructor(readonly items: BillItemViewModel[], private readonly _localization: LocalizationService) {}

  @computed
  get title(): string | undefined {
    return this.items.length > 0 && this.items[0].category != null
      ? this._localization.localizedStrings.authenticated.bills.groupingTitle(
          this.items[0].category,
          sum(this.items.map((item) => item.amount))
        )
      : undefined;
  }

  get numberOfRows(): number {
    return this.items.length;
  }
}
