import { ImmutableMoment } from '../../../utils';

export interface DateStrings {
  numericDateFormat: string;

  dateFormat(date: ImmutableMoment): string;
  humanize(date: ImmutableMoment): string;
}

export const EnglishDateStrings: DateStrings = {
  numericDateFormat: 'DD/MM/YYYY',

  dateFormat: (date: ImmutableMoment) => date.format('MMMM Do'),
  humanize: (date) => {
    return date.calendar(undefined, {
      lastWeek: '[Last] dddd',
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: `[${date.endOf('day').fromNow()}]`,
      sameElse: `[${date.endOf('day').fromNow()}]`
    });
  }
};
