import { DataStore, LocalizationService, NavigationService } from '../../../services';
import { computed, makeObservable } from 'mobx';
import { BillItemViewModel } from './BillItemViewModel';
import { sortBy } from 'lodash';
import { getRecurringBills } from '../../../utils';
import { BillListViewModel } from './BillListViewModel';
import { Period } from '../../../models';

export class LateBillsViewModel extends BillListViewModel {
  constructor(
    private readonly _dataStore: DataStore,
    private readonly _navigation: NavigationService,
    localization: LocalizationService
  ) {
    super(localization);
    makeObservable(this);
  }

  @computed
  get items(): BillItemViewModel[] {
    return sortBy(getRecurringBills(this._dataStore.bills, { end: this.period.end }), [
      (bill) => (this.groupByCategory ? bill.category ?? '' : undefined),
      (bill) => bill.recurrenceData.date.unix(),
      'name'
    ])
      .map((bill) => new BillItemViewModel(this._dataStore, this._localization, this._navigation, bill))
      .filter((bill) => bill.isLate);
  }

  @computed
  get groupByCategory(): boolean {
    return this._dataStore.settings?.grouping?.late ?? false;
  }

  @computed
  private get period(): Period {
    return this._dataStore.period ?? this._dataStore.defaultPeriod;
  }
}
