import * as React from 'react';
import { observer } from 'mobx-react';
import { Alert } from '@material-ui/lab';
import { useServices, useViewModel } from '../../hooks';
import * as MUI from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';

export interface PeriodProps {
  className?: string;
}

export const Period: React.FunctionComponent<PeriodProps> = observer(({ className }) => {
  const { viewModels, localization } = useServices();
  const viewModel = useViewModel(() => viewModels!.createPeriodViewModel());
  const strings = localization.localizedStrings.authenticated.dashboard.period;
  const classes = useStyles();

  return (
    <MUI.Box className={className}>
      {viewModel.hasPeriod ? (
        <MUI.Card>
          <MUI.CardHeader
            title={
              viewModel.isActive ? (
                strings.remainingDays(viewModel.remainingDays)
              ) : (
                <MUI.Box display={'flex'} alignItems={'center'}>
                  <MUI.Tooltip title={strings.inactivePeriodInfo(viewModel.start!, viewModel.end!)}>
                    <InfoIcon className={classes.infoIcon} />
                  </MUI.Tooltip>
                  <MUI.Typography variant={'h5'}>{strings.inactivePeriod}</MUI.Typography>
                </MUI.Box>
              )
            }
            action={
              <MUI.IconButton onClick={() => viewModel.editPeriod()}>
                <EditIcon />
              </MUI.IconButton>
            }
          />
          <MUI.CardContent>
            <MUI.Typography>{strings.dateRange(viewModel.start!, viewModel.end!)}</MUI.Typography>
          </MUI.CardContent>
        </MUI.Card>
      ) : (
        <Alert
          severity="info"
          action={<MUI.Button onClick={() => viewModel.editPeriod()}>{strings.addPeriod}</MUI.Button>}
        >
          {strings.noPeriod}
        </Alert>
      )}
    </MUI.Box>
  );
});

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    infoIcon: {
      marginRight: theme.spacing(1)
    }
  })
);
