import { createBrowserHistory, History } from 'history';
import {
  AuthenticationService,
  DataStore,
  EnvironmentService,
  LocalizationService,
  ModalService,
  NavigationService,
  RouteService,
  ThemeService,
  TransportService
} from '../services';

export class ServiceProvider {
  readonly history: History = createBrowserHistory();

  readonly environment: EnvironmentService = new EnvironmentService();
  readonly authentication: AuthenticationService = new AuthenticationService(this.environment);
  readonly route: RouteService = new RouteService(this.history);
  readonly theme: ThemeService = new ThemeService();
  readonly modal: ModalService = new ModalService(this.theme);
  readonly navigation: NavigationService = new NavigationService(this.history, this.route, this.modal);
  readonly localization: LocalizationService = new LocalizationService();
  readonly transport: TransportService = new TransportService(this.authentication, this.environment);

  readonly dataStore: DataStore = new DataStore(this.authentication, this.transport, this.localization);
}
