import * as React from 'react';
import Icon from '@material-ui/icons/UndoRounded';
import * as MUI from '@material-ui/core';
import { useServices } from '../../hooks';

export interface AddBillIndicatorProps {
  className?: string;
}

export const AddBillIndicator: React.FunctionComponent<AddBillIndicatorProps> = ({ className }) => {
  const { localization } = useServices();
  const classes = useStyles();
  const strings = localization.localizedStrings.authenticated.main.appBar;

  return (
    <MUI.Box display={'flex'} alignItems={'center'} className={className}>
      <MUI.Typography variant={'subtitle2'} className={classes.text}>
        <MUI.Hidden smUp>{strings.shortAddBillIndicator}</MUI.Hidden>
        <MUI.Hidden xsDown>{strings.addBillIndicator}</MUI.Hidden>
      </MUI.Typography>
      <Icon className={classes.icon} fontSize={'large'} />
    </MUI.Box>
  );
};

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    text: {
      fontWeight: theme.typography.fontWeightBold
    },
    icon: {
      transform: 'rotate(-210deg)',
      marginRight: theme.spacing(2)
    }
  })
);
