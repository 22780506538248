import { EditableModel } from './EditableModel';
import { ListGrouping } from '../api';
import { computed, makeObservable } from 'mobx';
import { EditableProperty } from './EditableProperty';

export class EditableListGrouping extends EditableModel<ListGrouping> implements ListGrouping {
  private _all: EditableProperty<boolean, ListGrouping>;
  private _late: EditableProperty<boolean, ListGrouping>;
  private _upcoming: EditableProperty<boolean, ListGrouping>;

  constructor(private readonly _grouping: ListGrouping | undefined) {
    super(_grouping == null);
    makeObservable(this);

    this.setFields([
      (this._all = new EditableProperty<boolean, ListGrouping>(_grouping?.all ?? false)),
      (this._late = new EditableProperty<boolean, ListGrouping>(_grouping?.late ?? false)),
      (this._upcoming = new EditableProperty<boolean, ListGrouping>(_grouping?.upcoming ?? false))
    ]);
  }

  @computed
  get all(): boolean {
    return this._all.value!;
  }

  set all(value: boolean) {
    this._all.value = value;
  }

  @computed
  get late(): boolean {
    return this._late.value!;
  }

  set late(value: boolean) {
    this._late.value = value;
  }

  @computed
  get upcoming(): boolean {
    return this._upcoming.value!;
  }

  set upcoming(value: boolean) {
    this._upcoming.value = value;
  }
}
