import * as React from 'react';
import { DialogResult } from '../DialogResult';
import { ResponsiveDialog } from '../utils';
import * as MUI from '@material-ui/core';
import { useServices, useViewModel } from '../../hooks';
import { observer } from 'mobx-react';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { immutableMoment } from '../../utils';
import { RecurrenceField } from './RecurrenceField';

export interface EditBillProps extends DialogResult<void> {
  billId?: string;
}

export const EditBill: React.FunctionComponent<EditBillProps> = observer(({ billId, onSuccess, onCancel }) => {
  const { viewModels, localization } = useServices();
  const viewModel = useViewModel(() => viewModels.createBillEditViewModel(billId, onSuccess!, onCancel!));
  const strings = localization.localizedStrings.authenticated.bills;
  const classes = useStyles();

  return (
    <ResponsiveDialog
      open
      maxWidth={'sm'}
      fullWidth
      onClose={() => viewModel.cancel()}
      title={viewModel.shouldBeCreated ? strings.addDialogTitle : strings.editDialogTitle}
      canSave={viewModel.canSave}
      canClose={!viewModel.isSaving}
      onSave={() => viewModel.save()}
    >
      <MUI.DialogContent>
        <MUI.Box display={'flex'} flexDirection={'column'}>
          {viewModel.isSaving ? (
            <MUI.CircularProgress className={classes.spinner} />
          ) : (
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <MUI.TextField
                className={classes.field}
                label={strings.name}
                required
                value={viewModel.name}
                onChange={(event) => (viewModel.name = event.target.value)}
              />
              <MUI.TextField
                className={classes.field}
                label={strings.amountLabel}
                required
                type={'number'}
                inputProps={{
                  min: 0
                }}
                value={viewModel.amount ?? ''}
                onChange={(event) =>
                  (viewModel.amount = event.target.value.length === 0 ? undefined : parseFloat(event.target.value))
                }
              />
              <Autocomplete
                className={classes.field}
                freeSolo
                renderInput={(p) => (
                  <MUI.TextField
                    {...p}
                    label={strings.account}
                    onChange={(event) => (viewModel.account = event.target.value ?? undefined)}
                  />
                )}
                value={viewModel.account ?? ''}
                onChange={(event, value) => (viewModel.account = value ?? undefined)}
                options={viewModel.accountsList}
              />
              <Autocomplete
                className={classes.field}
                freeSolo
                renderInput={(p) => (
                  <MUI.TextField
                    {...p}
                    label={strings.category}
                    onChange={(event) => (viewModel.category = event.target.value ?? undefined)}
                  />
                )}
                value={viewModel.category ?? ''}
                onChange={(event, value) => (viewModel.category = value ?? undefined)}
                options={viewModel.categoriesList}
              />
              <KeyboardDatePicker
                className={classes.field}
                required
                label={strings.date}
                format={strings.datePickerFormat}
                inputValue={viewModel.date.format(strings.datePickerFormat)}
                value={viewModel.date.moment}
                onChange={(value) => (viewModel.date = immutableMoment(value))}
              />
              <RecurrenceField viewModel={viewModel.recurrenceData} className={classes.field} />
            </MuiPickersUtilsProvider>
          )}
        </MUI.Box>
      </MUI.DialogContent>
      <MUI.DialogActions>
        <MUI.Button disabled={!viewModel.canSave} color={'primary'} onClick={() => viewModel.save()}>
          {strings.save}
        </MUI.Button>
        <MUI.Button disabled={viewModel.isSaving} onClick={() => viewModel.cancel()}>
          {strings.cancel}
        </MUI.Button>
      </MUI.DialogActions>
    </ResponsiveDialog>
  );
});

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    spinner: {
      margin: 'auto'
    },
    field: {
      marginTop: theme.spacing(1)
    }
  })
);
