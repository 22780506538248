import { createMuiTheme, Theme } from '@material-ui/core';
import { MuiTheme } from '../resources/themes';
import { computed, makeObservable } from 'mobx';

export class ThemeService {
  constructor() {
    makeObservable(this);
  }

  @computed
  get muiTheme(): Theme {
    return createMuiTheme(MuiTheme);
  }
}
