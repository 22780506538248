import moment, { CalendarSpec, DurationInputArg1, DurationInputArg2, Moment, MomentInput, unitOfTime } from 'moment';
import { ImmutableDuration } from './ImmutableDuration';
import { RecurrenceData } from '../models';
const recurMoment = require('moment-recur');

export interface Range {
  start?: ImmutableMoment;
  end: ImmutableMoment;
}

export function immutableMoment(
  inp?: moment.MomentInput,
  format?: moment.MomentFormatSpecification,
  language?: string,
  strict?: boolean
): ImmutableMoment {
  return new ImmutableMoment(moment(inp, format, language, strict));
}

export class ImmutableMoment {
  constructor(private readonly _moment: Moment) {}

  get moment(): Moment {
    return this._moment.clone();
  }

  static duration(inp?: DurationInputArg1, unit?: DurationInputArg2): ImmutableDuration {
    return new ImmutableDuration(moment.duration(inp, unit));
  }

  isAfter(moment: ImmutableMoment, granularity?: unitOfTime.StartOf) {
    return this._moment.isAfter(moment._moment, granularity);
  }

  isBefore(moment: ImmutableMoment, granularity?: unitOfTime.StartOf) {
    return this._moment.isBefore(moment._moment, granularity);
  }

  isSame(moment: ImmutableMoment, granularity?: unitOfTime.StartOf) {
    return this._moment.isSame(moment._moment, granularity);
  }

  isSameOrAfter(moment: ImmutableMoment, granularity?: unitOfTime.StartOf) {
    return this._moment.isSameOrAfter(moment._moment, granularity);
  }

  isSameOrBefore(moment: ImmutableMoment, granularity?: unitOfTime.StartOf) {
    return this._moment.isSameOrBefore(moment._moment, granularity);
  }

  isBetween(
    from: ImmutableMoment,
    to: ImmutableMoment,
    granularity?: unitOfTime.StartOf,
    inclusivity?: '()' | '[)' | '(]' | '[]'
  ) {
    return this._moment.isBetween(from._moment, to._moment, granularity, inclusivity);
  }

  diff(other: ImmutableMoment, unitOfTime?: unitOfTime.Diff, precise?: boolean) {
    return this._moment.diff(other._moment, unitOfTime, precise);
  }

  format(format?: string) {
    return this._moment.format(format);
  }

  toISOString() {
    return this._moment.toISOString();
  }

  fromNow(withoutSuffix?: boolean | undefined) {
    return this._moment.fromNow(withoutSuffix);
  }

  calendar(time?: MomentInput, formats?: CalendarSpec) {
    return this._moment.calendar(time, formats);
  }

  local(keepLocalTime?: boolean): ImmutableMoment {
    return new ImmutableMoment(this.moment.local(keepLocalTime));
  }

  unix(): number {
    return this._moment.unix();
  }

  year() {
    return this._moment.year();
  }

  month() {
    return this._moment.month();
  }

  day() {
    return this._moment.day();
  }

  add(amount?: DurationInputArg1, unit?: DurationInputArg2) {
    return new ImmutableMoment(this.moment.add(amount, unit));
  }

  subtract(amount?: DurationInputArg1, unit?: DurationInputArg2) {
    return new ImmutableMoment(this.moment.subtract(amount, unit));
  }

  startOf(unitOfTime: unitOfTime.StartOf) {
    return new ImmutableMoment(this.moment.startOf(unitOfTime));
  }

  endOf(unitOfTime: unitOfTime.StartOf) {
    return new ImmutableMoment(this.moment.endOf(unitOfTime));
  }

  hour(h: number): ImmutableMoment {
    return new ImmutableMoment(this.moment.hour(h));
  }

  minute(m: number): ImmutableMoment {
    return new ImmutableMoment(this.moment.minute(m));
  }

  second(s: number): ImmutableMoment {
    return new ImmutableMoment(this.moment.second(s));
  }
}

export function getRecurringMoments(range: Range, recurrenceData: RecurrenceData): ImmutableMoment[] {
  const endDate = recurrenceData.endDate?.isBefore(range.end, 'day') ? recurrenceData.endDate : range.end;

  if (recurrenceData.date.isAfter(endDate, 'day')) {
    return [];
  }
  if (recurrenceData.kind === 'Once') {
    return recurrenceData.date.isSameOrBefore(endDate, 'day') ? [recurrenceData.date] : [];
  }

  const interval = recurMoment(recurrenceData.date.moment)
    .recur(recurrenceData.date.moment, endDate.moment)
    .every(recurrenceData.interval);

  let date;
  switch (recurrenceData.kind) {
    case 'Daily':
      date = interval.days();
      break;
    case 'Weekly':
      date = interval.weeks();
      break;
    case 'Monthly':
      date = interval.month();
      break;
    case 'Yearly':
      date = interval.year();
      break;
  }

  return date
    .all()
    .map((o: Moment) =>
      immutableMoment(o.local(true))
        .hour(recurrenceData.date.moment.hour())
        .minute(recurrenceData.date.moment.minute())
        .second(recurrenceData.date.moment.second())
    )
    .filter((d: ImmutableMoment) => (range.start != null ? d.isSameOrAfter(range.start) : true));
}

export function getNextRecurringDate(recurrenceData: RecurrenceData): ImmutableMoment {
  const interval = recurMoment(recurrenceData.date.moment).recur().every(recurrenceData.interval);

  let nextDate;
  switch (recurrenceData.kind) {
    case 'Once':
      nextDate = recurrenceData.date;
      break;
    case 'Daily':
      nextDate = interval.days().next(1)[0];
      break;
    case 'Weekly':
      nextDate = interval.weeks().next(1)[0];
      break;
    case 'Monthly':
      nextDate = interval.month().next(1)[0];
      break;
    case 'Yearly':
      nextDate = interval.year().next(1)[0];
      break;
  }

  return immutableMoment(nextDate.local(true))
    .hour(recurrenceData.date.moment.hour())
    .minute(recurrenceData.date.moment.minute())
    .second(recurrenceData.date.moment.second());
}
