import { immutableMoment, ImmutableMoment } from '../utils';
import { RecurrenceKind, RecurrenceDataContract } from './api';

export interface RecurrenceData {
  readonly date: ImmutableMoment;
  readonly kind: RecurrenceKind;
  readonly interval?: number;
  readonly endDate?: ImmutableMoment;
}

export class AppRecurrenceData implements RecurrenceData {
  constructor(private readonly _recurrenceData: RecurrenceDataContract) {}

  get date(): ImmutableMoment {
    return immutableMoment(this._recurrenceData.date).startOf('day');
  }

  get kind(): RecurrenceKind {
    return this._recurrenceData.type;
  }

  get interval(): number | undefined {
    return this._recurrenceData.interval;
  }

  get endDate(): ImmutableMoment | undefined {
    return this._recurrenceData.endDate == null
      ? undefined
      : immutableMoment(this._recurrenceData.endDate).endOf('day');
  }
}
