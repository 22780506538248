import { EnvironmentService } from './EnvironmentService';
import { observable, makeObservable, action } from 'mobx';
import * as firebase from 'firebase/app';
import 'firebase/auth';

export class AuthenticationService {
  @observable private _user: firebase.User | undefined;
  @observable private _isLoading: boolean = true;

  constructor(environment: EnvironmentService) {
    makeObservable(this);
    firebase.initializeApp(environment.firebaseConfig);
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
  }

  get user(): firebase.User | undefined {
    return this._user;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  login() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithRedirect(provider);
  }

  logout() {
    return firebase.auth().signOut();
  }

  @action
  private onAuthStateChanged = (user: firebase.User | null) => {
    this._user = user ?? undefined;
    this._isLoading = false;
  };
}
