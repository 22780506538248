import { autorun, computed, observable, makeObservable, action, runInAction } from 'mobx';
import { AppBill, AppPeriod, AppSettings, Bill, Period, Settings } from '../models';
import { TransportService } from './TransportService';
import { AuthenticationService } from './AuthenticationService';
import Fuse from 'fuse.js';
import { uniqBy, compact, findIndex } from 'lodash';
import { immutableMoment } from '../utils';
import { LocalizationService } from './LocalizationService';

export class DataStore {
  @observable private _period: Period | undefined;
  @observable private _settings: Settings | undefined;
  @observable private _bills: Bill[] = [];
  @observable private _searchFilter: string | undefined;
  @observable private _isLoading: boolean = false;
  @observable private _errorMessage: string | undefined;

  constructor(
    authentication: AuthenticationService,
    private readonly _transport: TransportService,
    private readonly _localization: LocalizationService
  ) {
    makeObservable(this);
    autorun(() => authentication.user != null && this.loadAll());
  }

  @computed
  get period(): Period | undefined {
    return this._period;
  }

  get defaultPeriod(): Period {
    return new AppPeriod({
      start: immutableMoment().toISOString(),
      end: immutableMoment().add(1, 'week').toISOString()
    });
  }

  @computed
  get settings(): Settings | undefined {
    return this._settings;
  }

  @computed
  get bills(): Bill[] {
    if (this._searchFilter == null) {
      return this._bills;
    }

    const fuse = new Fuse(this._bills, {
      minMatchCharLength: this._searchFilter.length,
      keys: ['amount', 'name', 'account', 'category']
    });
    return fuse.search(this._searchFilter).map((res) => res.item);
  }

  @computed
  get accounts(): string[] {
    return compact(uniqBy(this._bills, 'account').map((bill) => bill.account));
  }

  @computed
  get categories(): string[] {
    return compact(uniqBy(this._bills, 'category').map((bill) => bill.category));
  }

  set searchFilter(value: string | undefined) {
    this._searchFilter = value;
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get errorMessage(): string | undefined {
    return this._errorMessage;
  }

  set errorMessage(value) {
    this._errorMessage = value;
  }

  async updatePeriod(period: Period) {
    try {
      await this._transport.updatePeriod(period.asContract);
      runInAction(() => (this._period = period));
    } catch (e) {
      console.error(e);
      runInAction(
        () => (this._errorMessage = this._localization.localizedStrings.authenticated.errors.updatePeriod(e))
      );
    }
  }

  async updateSettings(settings: Settings) {
    try {
      await this._transport.updateSettings(settings.asContract);
      runInAction(() => (this._settings = settings));
    } catch (e) {
      console.error(e);
      runInAction(
        () => (this._errorMessage = this._localization.localizedStrings.authenticated.errors.updateSettings(e))
      );
    }
  }

  async addBill(bill: Bill) {
    try {
      const newBill = await this._transport.addBill(bill.asContract);
      runInAction(() => this._bills.push(new AppBill(newBill)));
    } catch (e) {
      console.error(e);
      runInAction(() => (this._errorMessage = this._localization.localizedStrings.authenticated.errors.addBill(e)));
    }
  }

  async updateBill(bill: Bill) {
    try {
      await this._transport.updateBill(bill.asContract);
      const index = findIndex(this._bills, { id: bill.id });
      runInAction(() => (this._bills[index] = new AppBill(bill.asContract)));
    } catch (e) {
      console.error(e);
      runInAction(() => (this._errorMessage = this._localization.localizedStrings.authenticated.errors.updateBill(e)));
    }
  }

  async deleteBill(bill: Bill) {
    try {
      await this._transport.deleteBill(bill.id);
      const index = findIndex(this._bills, { id: bill.id });
      runInAction(() => this._bills.splice(index, 1));
    } catch (e) {
      console.error(e);
      runInAction(() => (this._errorMessage = this._localization.localizedStrings.authenticated.errors.deleteBill(e)));
    }
  }

  @action
  private async loadAll() {
    this._isLoading = true;
    try {
      await Promise.all([this.loadPeriod(), this.loadSettings(), this.loadBills()]);
    } catch (e) {
      console.error(e);
      runInAction(() => (this._errorMessage = this._localization.localizedStrings.authenticated.errors.loadAll));
    } finally {
      runInAction(() => (this._isLoading = false));
    }
  }

  private async loadPeriod() {
    const periodContract = await this._transport.getPeriod();
    this._period = periodContract != null ? new AppPeriod(periodContract) : undefined;
  }

  private async loadSettings() {
    const settingsContract = await this._transport.getSettings();
    this._settings = settingsContract != null ? new AppSettings(settingsContract) : undefined;
  }

  private async loadBills() {
    const bills = await this._transport.getBills();
    this._bills = bills.map((bill) => new AppBill(bill));
  }
}
