import * as React from 'react';
import { observer } from 'mobx-react';
import * as MUI from '@material-ui/core';
import { ListHeader } from './ListHeader';
import { List } from './List';
import { EmptyListIndicator } from './EmptyListIndicator';
import EmptyListIcon from '@material-ui/icons/CheckCircleRounded';
import { BillListItem } from './BillListItem';
import { useServices } from '../../hooks';
import { UpcomingBillsViewModel } from '../../viewmodels';

export interface UpcomingBillsListProps {
  viewModel: UpcomingBillsViewModel;
  className?: string;
}

export const UpcomingBillsList: React.FunctionComponent<UpcomingBillsListProps> = observer(
  ({ className, viewModel }) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.authenticated.bills;
    const classes = useStyles();

    return (
      <MUI.Box className={className} display="flex" flexDirection="column">
        <MUI.Hidden xsDown>
          <ListHeader className={classes.header} title={strings.upcomingBillsListTitle} />
        </MUI.Hidden>

        <MUI.Paper className={classes.list}>
          <MUI.Box clone width="100%" height="100%">
            <List
              className={classes.listContent}
              sections={viewModel.sections}
              renderEmptyIndicator={() => (
                <EmptyListIndicator
                  title={strings.upcomingBillsEmptyListTitle}
                  subtitle={strings.upcomingBillsEmptyListSubtitle}
                  renderIcon={(className) => <EmptyListIcon className={className} />}
                />
              )}
              renderItem={(section, row) => (
                <BillListItem
                  item={viewModel.sections[section].items[row]}
                  isGroupedByCategory={viewModel.groupByCategory}
                />
              )}
            />
          </MUI.Box>
        </MUI.Paper>
      </MUI.Box>
    );
  }
);

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    header: {
      marginLeft: theme.spacing(1)
    },
    list: {
      display: 'flex',
      flex: 1,
      marginTop: theme.spacing(2),

      [theme.breakpoints.only('xs')]: {
        marginTop: theme.spacing(0)
      }
    },
    listContent: {
      width: '100%'
    }
  })
);
