import * as React from 'react';
import { observer } from 'mobx-react';
import * as MUI from '@material-ui/core';
import { BillItemViewModel } from '../../viewmodels';
import PayIcon from '@material-ui/icons/DoneRounded';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import { SwipeableListItem } from '../utils';
import { useTheme } from '@material-ui/core';
import { useServices } from '../../hooks';
import ExpiredIcon from '@material-ui/icons/WarningRounded';

export interface BillListItemProps {
  item: BillItemViewModel;
  isGroupedByCategory: boolean;
  className?: string;
}

export const BillListItem: React.FunctionComponent<BillListItemProps> = observer(
  ({ className, item, isGroupedByCategory }) => {
    const { localization } = useServices();
    const theme = useTheme();
    const strings = localization.localizedStrings.authenticated.bills;

    return (
      <MUI.Box clone className={className} display="flex">
        <SwipeableListItem
          leftAction={{
            icon: <PayIcon />,
            color: theme.palette.primary.main,
            confirm: true,
            confirmMessage: strings.payConfirm,
            onSwiped: () => item.pay()
          }}
          rightAction={{
            icon: <DeleteIcon />,
            color: theme.palette.error.main,
            confirm: true,
            confirmMessage: strings.deleteConfirm,
            onSwiped: () => item.delete()
          }}
        >
          {(props) => (
            <MUI.ListItem {...props} button onClick={() => item.edit()}>
              <MUI.ListItemAvatar>
                <MUI.Avatar src={item.imageUrl} />
              </MUI.ListItemAvatar>
              <MUI.Box clone>
                <MUI.ListItemText
                  primary={item.title}
                  secondary={strings.amount(item.amount)}
                  primaryTypographyProps={{ noWrap: true }}
                />
              </MUI.Box>
              <MUI.Box display="flex" flexDirection="column" alignItems="flexEnd">
                {item.isExpired ? (
                  <MUI.Tooltip title={strings.expiredTooltip}>
                    <ExpiredIcon color={'error'} />
                  </MUI.Tooltip>
                ) : (
                  <MUI.ListItemText
                    primary={item.dueDate}
                    secondary={isGroupedByCategory ? undefined : item.category}
                    primaryTypographyProps={{
                      variant: 'body2',
                      color: item.isLate ? 'error' : item.isToday ? 'primary' : 'textSecondary',
                      noWrap: true,
                      align: 'right'
                    }}
                    secondaryTypographyProps={{
                      variant: 'body2',
                      noWrap: true,
                      align: 'right'
                    }}
                  />
                )}
              </MUI.Box>
            </MUI.ListItem>
          )}
        </SwipeableListItem>
      </MUI.Box>
    );
  }
);
