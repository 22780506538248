import { EditablePeriod, Period } from '../../../models';
import { action, computed, observable, makeObservable, runInAction } from 'mobx';
import { ImmutableMoment } from '../../../utils';
import { DataStore } from '../../../services';

export class PeriodEditViewModel {
  private readonly _editablePeriod: EditablePeriod;
  @observable private _isSaving: boolean = false;

  constructor(
    private _dataStore: DataStore,
    private _period: Period | undefined,
    private _onSuccess: () => void,
    private _onCancel: () => void
  ) {
    makeObservable(this);
    this._editablePeriod = new EditablePeriod(_period);
  }

  @computed
  get start(): ImmutableMoment {
    return this._editablePeriod.start;
  }

  set start(value) {
    this._editablePeriod.start = value;
  }

  @computed
  get end(): ImmutableMoment {
    return this._editablePeriod.end;
  }

  set end(value) {
    this._editablePeriod.end = value;
  }

  @computed
  get canSave(): boolean {
    return !this.isSaving && this.end.isAfter(this.start);
  }

  @computed
  get isSaving(): boolean {
    return this._isSaving;
  }

  @action
  async save() {
    this._isSaving = true;
    await this._dataStore.updatePeriod(this._editablePeriod);
    this._onSuccess();
    runInAction(() => (this._isSaving = false));
  }

  @action
  cancel() {
    if (!this.isSaving) {
      this._onCancel();
    }
  }
}
