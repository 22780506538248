import { computed, makeObservable } from 'mobx';
import { DataStore, LocalizationService, NavigationService } from '../../../services';
import { BillItemViewModel } from './BillItemViewModel';
import { sortBy } from 'lodash';
import { BillListViewModel } from './BillListViewModel';

export class AllBillsViewModel extends BillListViewModel {
  constructor(
    private readonly _dataStore: DataStore,
    private readonly _navigation: NavigationService,
    localization: LocalizationService
  ) {
    super(localization);
    makeObservable(this);
  }

  @computed
  get items(): BillItemViewModel[] {
    return sortBy(this._dataStore.bills, [
      (bill) => (this.groupByCategory ? bill.category ?? '' : undefined),
      (bill) => (bill.isExpired ? 0 : 1),
      (bill) => bill.recurrenceData.date.unix(),
      'name'
    ]).map((bill) => new BillItemViewModel(this._dataStore, this._localization, this._navigation, bill));
  }

  @computed
  get groupByCategory(): boolean {
    return this._dataStore.settings?.grouping?.all ?? false;
  }
}
