import * as React from 'react';
import { DialogResult, ResponsiveDialog } from '../index';
import { observer } from 'mobx-react';
import { Period } from '../../models';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { immutableMoment } from '../../utils';
import moment from 'moment';
import { useServices, useViewModel } from '../../hooks';
import * as MUI from '@material-ui/core';

export interface EditPeriodProps extends DialogResult<void> {
  period: Period | undefined;
}

export const EditPeriod: React.FunctionComponent<EditPeriodProps> = observer(({ period, onSuccess, onCancel }) => {
  const { viewModels, localization } = useServices();
  const viewModel = useViewModel(() => viewModels!.createPeriodEditViewModel(period, onSuccess!, onCancel!));
  const strings = localization.localizedStrings.authenticated.dashboard.period;
  const classes = useStyles();

  return (
    <ResponsiveDialog
      open
      maxWidth={'sm'}
      fullWidth
      onClose={() => viewModel.cancel()}
      title={strings.dialogTitle}
      canSave={viewModel.canSave}
      canClose={!viewModel.isSaving}
      onSave={() => viewModel.save()}
    >
      <MUI.DialogContent>
        <MUI.Box display={'flex'} flexDirection={'column'}>
          {viewModel.isSaving ? (
            <MUI.CircularProgress className={classes.spinner} />
          ) : (
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                className={classes.field}
                required
                label={strings.start}
                format={strings.datePickerFormat}
                inputValue={viewModel.start.format(strings.datePickerFormat)}
                value={viewModel.start.moment}
                onChange={(value) => (viewModel.start = immutableMoment(value))}
              />
              <KeyboardDatePicker
                className={classes.field}
                required
                minDate={viewModel.start.moment}
                label={strings.end}
                format={strings.datePickerFormat}
                inputValue={viewModel.end.format(strings.datePickerFormat)}
                value={viewModel.end.moment}
                onChange={(value) => (viewModel.end = immutableMoment(value))}
              />
            </MuiPickersUtilsProvider>
          )}
        </MUI.Box>
      </MUI.DialogContent>
      <MUI.DialogActions>
        <MUI.Button disabled={!viewModel.canSave} color={'primary'} onClick={() => viewModel.save()}>
          {strings.save}
        </MUI.Button>
        <MUI.Button disabled={viewModel.isSaving} onClick={() => viewModel.cancel()}>
          {strings.cancel}
        </MUI.Button>
      </MUI.DialogActions>
    </ResponsiveDialog>
  );
});

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    spinner: {
      margin: 'auto'
    },
    field: {
      marginTop: theme.spacing(1)
    }
  })
);
