import { ThemeOptions } from '@material-ui/core';

export const MuiTheme: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#3A9AE8'
    },
    secondary: {
      main: '#F5CE27'
    }
  }
};
