import * as React from 'react';
import * as MUI from '@material-ui/core';
import { observer } from 'mobx-react';

export interface EmptyListIndicatorProps {
  className?: string;
  style?: React.CSSProperties;
  /**
   * The title
   */
  title: string;
  /**
   * The subtitle
   */
  subtitle: string;

  /**
   * The icon to display.
   * @param className The icon's className
   * @return JSX.Element The icon element
   */
  renderIcon: (className: string) => JSX.Element;
}

export const EmptyListIndicator: React.FunctionComponent<EmptyListIndicatorProps> = observer(
  ({ className, style, renderIcon, title, subtitle }) => {
    const classes = useStyles();

    return (
      <MUI.Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={className}
        style={style}
      >
        {renderIcon(classes.icon)}

        <MUI.Box marginTop={2}>
          <MUI.Typography variant="h4" color="textSecondary" className={classes.title}>
            {title}
          </MUI.Typography>
          <pre className={classes.subtitle}>{subtitle}</pre>
        </MUI.Box>
      </MUI.Box>
    );
  }
);

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    title: {
      textAlign: 'center'
    },
    subtitle: {
      ...theme.typography.body1,
      margin: 0,
      color: theme.palette.text.secondary,
      textAlign: 'center'
    },
    icon: {
      color: theme.palette.text.secondary,
      fontSize: 96
    }
  })
);
