import * as React from 'react';
import { ServicesContext, ServicesContextValues } from '../../contexts';
import { ServiceProvider, ViewModelProvider } from '../../providers';
import { observer } from 'mobx-react';

export interface ServicesProviderProps {
  children?: React.ReactNode;
}

export const ServicesProvider: React.FunctionComponent<ServicesProviderProps> = observer(
  ({ children }: ServicesProviderProps) => (
    <ServicesContext.Provider value={ServicesContainer.instance.services}>{children}</ServicesContext.Provider>
  )
);

class ServicesContainer {
  services: ServicesContextValues;

  private static _instance: ServicesContainer | undefined;

  static get instance(): ServicesContainer {
    if (this._instance == null) {
      this._instance = new ServicesContainer();
    }

    return this._instance;
  }

  private constructor() {
    const services = new ServiceProvider();
    const viewModels = new ViewModelProvider(services);
    this.services = { ...services, viewModels };
  }
}
