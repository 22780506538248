import { History, LocationDescriptorObject } from 'history';

export class RouteService {
  constructor(private readonly _history: History) {}

  /**
   * Takes a route template and return a new location object to use with the navigation.
   * @param routeTemplate The route template to use
   * @returns Returns a `LocationDescriptorObject` built using
   * the current location and the resolved route template as the pathname.
   */
  resolveLocation(routeTemplate: string): LocationDescriptorObject {
    return {
      ...this._history.location,
      pathname: routeTemplate
    };
  }

  /**
   * Get the referrer of the current location
   * @returns LocationDescriptorObject The current location referrer or undefined
   */
  getReferrer(): LocationDescriptorObject | undefined {
    return (this._history.location.state as any)?.referrer;
  }
}
