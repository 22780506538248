import { Duration, DurationInputArg2 } from 'moment';

export class ImmutableDuration {
  constructor(private readonly _duration: Duration) {}

  get duration(): Duration {
    return this._duration.clone();
  }

  milliseconds(): number {
    return this._duration.milliseconds();
  }
  asMilliseconds(): number {
    return this._duration.asMilliseconds();
  }

  seconds(): number {
    return this._duration.seconds();
  }
  asSeconds(): number {
    return this._duration.asSeconds();
  }

  minutes(): number {
    return this._duration.minutes();
  }
  asMinutes(): number {
    return this._duration.asMinutes();
  }

  hours(): number {
    return this._duration.hours();
  }
  asHours(): number {
    return this._duration.asHours();
  }

  days(): number {
    return this._duration.days();
  }
  asDays(): number {
    return this._duration.asDays();
  }

  weeks(): number {
    return this._duration.weeks();
  }
  asWeeks(): number {
    return this._duration.asWeeks();
  }

  months(): number {
    return this._duration.months();
  }
  asMonths(): number {
    return this._duration.asMonths();
  }

  years(): number {
    return this._duration.years();
  }
  asYears(): number {
    return this._duration.asYears();
  }

  add(inp?: ImmutableDuration, unit?: DurationInputArg2): ImmutableDuration {
    return new ImmutableDuration(this.duration.add(inp?.duration, unit));
  }

  subtract(inp?: ImmutableDuration, unit?: DurationInputArg2): ImmutableDuration {
    return new ImmutableDuration(this.duration.subtract(inp?.duration, unit));
  }
}
