import * as React from 'react';
import { AppBar, Box, Dialog, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

export interface ResponsiveDialogProps {
  open: boolean;
  fullScreenWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  title: string;
  children: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  className?: string;
  canSave?: boolean;
  canClose?: boolean;

  onClose: () => void;
  onSave?: () => void;
}

export function ResponsiveDialog({
  open,
  maxWidth = 'sm',
  fullWidth,
  children,
  onClose,
  className,
  title,
  onSave,
  canSave = true,
  canClose = true,
  fullScreenWidth = maxWidth
}: ResponsiveDialogProps) {
  const theme = useTheme();
  const isFullScreen = !useMediaQuery(theme.breakpoints.up(fullScreenWidth));

  return (
    <Dialog
      className={className}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onClose}
      fullScreen={isFullScreen}
    >
      <AppBar position={'relative'} color={'secondary'}>
        <Toolbar>
          {/* Close button (mobile) */}
          {isFullScreen && (
            <IconButton edge="start" color="inherit" onClick={onClose} disabled={!canClose}>
              <CloseIcon />
            </IconButton>
          )}

          {/* Title */}
          <Box flexGrow={1}>
            <Typography variant={'h6'}>{title}</Typography>
          </Box>

          {/* Save button (mobile) */}
          {isFullScreen && onSave != null && (
            <IconButton edge="end" color="inherit" disabled={!canSave} onClick={onSave}>
              <CheckIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}
