import { EditableModel } from './EditableModel';
import { Bill } from '../Bill';
import { computed, makeObservable } from 'mobx';
import { EditableProperty } from './EditableProperty';
import { ImmutableMoment } from '../../utils';
import { BillContract, PartialBillContract } from '../api';
import { merge, cloneDeep } from 'lodash';
import { EditableRecurrenceData } from './EditableRecurrenceData';

export class EditableBill extends EditableModel<Bill> implements Bill {
  private readonly _recurrenceData: EditableRecurrenceData;
  private _account: EditableProperty<string | undefined, Bill>;
  private _category: EditableProperty<string | undefined, Bill>;
  private _amount: EditableProperty<number, Bill>;
  private _name: EditableProperty<string, Bill>;

  constructor(private readonly _bill: Bill | undefined) {
    super(_bill == null);
    makeObservable(this);

    this.setFields(
      [
        (this._account = new EditableProperty<string | undefined, Bill>(_bill?.account)),
        (this._category = new EditableProperty<string | undefined, Bill>(_bill?.category)),
        (this._amount = new EditableProperty<number, Bill>(_bill?.amount)),
        (this._name = new EditableProperty<string, Bill>(_bill?.name))
      ],
      [(this._recurrenceData = new EditableRecurrenceData(_bill?.recurrenceData))]
    );
  }

  @computed
  get account(): string | undefined {
    return this._account.value;
  }

  set account(value: string | undefined) {
    this._account.value = value;
  }

  @computed
  get category(): string | undefined {
    return this._category.value;
  }

  set category(value: string | undefined) {
    this._category.value = value;
  }

  @computed
  get amount(): number {
    return this._amount.value ?? 0;
  }

  set amount(value: number) {
    this._amount.value = value;
  }

  @computed
  get date(): ImmutableMoment {
    return this._recurrenceData.date;
  }

  set date(value: ImmutableMoment) {
    this._recurrenceData.date = value;
  }

  get id(): string {
    return this._bill?.id ?? '';
  }

  @computed
  get name(): string {
    return this._name.value ?? '';
  }

  set name(value: string) {
    this._name.value = value;
  }

  @computed
  get recurrenceData(): EditableRecurrenceData {
    return this._recurrenceData;
  }

  @computed
  get isExpired(): boolean {
    if (this.recurrenceData.endDate == null) {
      return false;
    }
    return this.recurrenceData.endDate.isBefore(this.date, 'day');
  }

  @computed
  get asContract(): BillContract {
    return {
      id: this.id,
      name: this.name,
      amount: this.amount,
      account: this.account,
      category: this.category,
      recurrenceData: {
        date: this.date.toISOString(),
        type: this.recurrenceData.kind,
        interval: this.recurrenceData.interval,
        endDate: this.recurrenceData.endDate?.toISOString()
      }
    };
  }

  clone(bill?: PartialBillContract): Bill {
    return new EditableBill(merge(cloneDeep(this), bill));
  }
}
