import * as React from 'react';
import * as MUI from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchRounded';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { useState } from 'react';

export interface SearchBarProps {
  placeholder?: string;
  debounce?: number;
  className?: string;

  onSearch?: (value: string | undefined) => void;
}

export const SearchBar: React.FunctionComponent<SearchBarProps> = ({ className, placeholder, onSearch }) => {
  const classes = useStyles();
  const [displaySearch, setDisplaySearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const isSmallScreen = MUI.useMediaQuery((th: MUI.Theme) => th.breakpoints.down('xs'));
  const hidden = isSmallScreen ? (searchValue.length !== 0 ? false : !displaySearch) : false;

  return (
    <MUI.Box className={className}>
      {hidden && (
        <MUI.Grow in={hidden}>
          <MUI.IconButton onClick={() => setDisplaySearch(true)}>
            <SearchIcon className={classes.mobileSearchIcon} />
          </MUI.IconButton>
        </MUI.Grow>
      )}
      {!hidden && (
        <MUI.Grow in={!hidden}>
          <MUI.Box className={classes.search}>
            <MUI.Box className={classes.searchIcon}>
              <SearchIcon />
            </MUI.Box>
            <MUI.InputBase
              placeholder={placeholder}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              value={searchValue}
              onChange={(event) => {
                const value = event.target.value;
                setSearchValue(value);
                onSearch?.(value.length === 0 ? undefined : value);
              }}
              inputProps={{ 'aria-label': 'search' }}
              autoFocus={displaySearch}
              onBlur={() => searchValue.length === 0 && setDisplaySearch(false)}
            />
            <MUI.IconButton
              onClick={() => {
                setDisplaySearch(false);
                setSearchValue('');
                onSearch?.(undefined);
              }}
            >
              <ClearIcon className={classes.clearIcon} />
            </MUI.IconButton>
          </MUI.Box>
        </MUI.Grow>
      )}
    </MUI.Box>
  );
};

const iconButtonSize = 48;

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: MUI.fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: MUI.fade(theme.palette.common.white, 0.25)
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
      }
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    mobileSearchIcon: {
      color: theme.palette.primary.contrastText
    },
    inputRoot: {
      color: 'inherit',
      width: `calc(100% - ${iconButtonSize}px)`
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch'
        }
      }
    },
    clearIcon: {
      color: theme.palette.primary.contrastText
    }
  })
);
