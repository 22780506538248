import * as React from 'react';
import { DashboardViewModel, BillListKind, AllBillListKind } from '../../viewmodels';
import * as MUI from '@material-ui/core';
import { useServices } from '../../hooks';
import { observer } from 'mobx-react';

export interface TabsProps {
  viewModel: DashboardViewModel;
  className?: string;
}

export const Tabs: React.FunctionComponent<TabsProps> = observer(({ className, viewModel }) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.authenticated.bills;

  return (
    <MUI.Tabs
      className={className}
      value={viewModel.currentListKind}
      onChange={(e: React.ChangeEvent<{}>, value: BillListKind) => (viewModel.currentListKind = value)}
      aria-label="bills list"
      variant="scrollable"
    >
      {AllBillListKind.map((listKind, index) => (
        <MUI.Tab
          value={listKind}
          label={
            <MUI.Badge badgeContent={getBadgeCount(listKind, viewModel)} color="secondary">
              {strings.shortListTitle(listKind)}
            </MUI.Badge>
          }
          id={`tab-${index}`}
          key={`tab-${index}`}
          aria-controls={`swipeable-content-${index}`}
        />
      ))}
    </MUI.Tabs>
  );
});

function getBadgeCount(listKind: BillListKind, viewModel: DashboardViewModel): number {
  switch (listKind) {
    case 'all':
      return viewModel.allBillsBadgeCount;
    case 'upcoming':
      return viewModel.upcomingBillsBadgeCount;
    case 'late':
      return viewModel.lateBillsBadgeCount;

    default:
      return 0;
  }
}
