import { FirebaseConfig } from '../models';

export class EnvironmentService {
  readonly apiEndpoint: string = this.getEnvironmentVariable('REACT_APP_API_ENDPOINT');

  readonly firebaseConfig: FirebaseConfig = {
    apiKey: this.getEnvironmentVariable('REACT_APP_FIREBASE_API_KEY'),
    authDomain: this.getEnvironmentVariable('REACT_APP_FIREBASE_AUTH_DOMAIN'),
    databaseUrl: this.getEnvironmentVariable('REACT_APP_FIREBASE_DATABASE_URL'),
    projectId: this.getEnvironmentVariable('REACT_APP_FIREBASE_PROJECT_ID'),
    storageBucket: this.getEnvironmentVariable('REACT_APP_FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: this.getEnvironmentVariable('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
    appId: this.getEnvironmentVariable('REACT_APP_FIREBASE_APP_ID'),
    measurementId: this.getEnvironmentVariable('REACT_APP_FIREBASE_MEASUREMENT_ID')
  };

  private getEnvironmentVariable(key: string): string {
    return process.env[key]!;
  }
}
