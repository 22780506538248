import * as React from 'react';
import * as MUI from '@material-ui/core';
import { useServices } from '../../hooks';
import { observer } from 'mobx-react';
import { AllRecurrenceKind, EditableRecurrenceData, RecurrenceKind } from '../../models';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { immutableMoment } from '../../utils';

export interface RecurrenceFieldProps {
  viewModel: EditableRecurrenceData;
  className?: string;
}

export const RecurrenceField: React.FunctionComponent<RecurrenceFieldProps> = observer(({ className, viewModel }) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.authenticated.bills;
  const classes = useStyles();

  return (
    <MUI.Box className={className}>
      <MUI.FormControlLabel
        label={strings.isRecurring}
        control={
          <MUI.Checkbox
            checked={viewModel.kind !== 'Once'}
            onChange={(event, checked) => (checked ? (viewModel.kind = 'Daily') : (viewModel.kind = 'Once'))}
          />
        }
      />
      {viewModel.kind !== 'Once' && (
        <>
          <MUI.Box display={'flex'} alignItems={'center'}>
            <MUI.Typography>{strings.every}</MUI.Typography>
            <MUI.TextField
              className={classes.interval}
              required
              type={'number'}
              inputProps={{
                min: 1
              }}
              value={viewModel.interval}
              onChange={(event) =>
                (viewModel.interval = event.target.value.length === 0 ? undefined : parseInt(event.target.value))
              }
            />
            <MUI.Select
              required
              value={viewModel.kind}
              onChange={(event) => (viewModel.kind = event.target.value as RecurrenceKind)}
            >
              {AllRecurrenceKind.map(
                (kind) =>
                  kind !== 'Once' && (
                    <MUI.MenuItem key={kind} value={kind}>
                      {strings.recurrenceKind(kind, viewModel.interval!)}
                    </MUI.MenuItem>
                  )
              )}
            </MUI.Select>
          </MUI.Box>
          <KeyboardDatePicker
            className={classes.endDate}
            label={strings.endDate}
            format={strings.datePickerFormat}
            inputValue={viewModel.endDate?.format(strings.datePickerFormat)}
            value={viewModel.endDate?.moment ?? null}
            onChange={(value) => (viewModel.endDate = value == null ? undefined : immutableMoment(value))}
          />
        </>
      )}
    </MUI.Box>
  );
});

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    interval: {
      width: 50,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    endDate: {
      marginTop: theme.spacing(1)
    }
  })
);
