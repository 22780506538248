import { Bill, Period } from '../../../models';
import { DataStore, LocalizationService, NavigationService } from '../../../services';
import { computed, makeObservable } from 'mobx';
import { getNextRecurringDate, immutableMoment } from '../../../utils';
import { EditableBill } from '../../../models';

export class BillItemViewModel {
  constructor(
    private readonly _dataStore: DataStore,
    private readonly _localization: LocalizationService,
    private readonly _navigation: NavigationService,
    private readonly _bill: Bill
  ) {
    makeObservable(this);
  }

  @computed
  get title(): string {
    return this._localization.localizedStrings.authenticated.bills.itemTitle(this._bill.name, this._bill.account);
  }

  @computed
  get amount(): number {
    return this._bill.amount;
  }

  @computed
  get category(): string | undefined {
    return this._bill.category;
  }

  @computed
  get dueDate(): string {
    return this._localization.localizedStrings.authenticated.bills.dueDate(this._bill.recurrenceData.date);
  }

  get imageUrl(): string {
    // TODO: return image url
    return '';
  }

  get isLate(): boolean {
    return this.period.isActive
      ? this._bill.date.isBefore(immutableMoment(), 'date')
      : this._bill.date.isBefore(this.period.start, 'date');
  }

  get isToday(): boolean {
    return this._bill.recurrenceData.date.isSame(immutableMoment(), 'date');
  }

  get isExpired(): boolean {
    return this._bill.isExpired;
  }

  @computed
  private get period(): Period {
    return this._dataStore.period ?? this._dataStore.defaultPeriod;
  }

  edit() {
    this._navigation.navigateToBillEdit(this._bill.id);
  }

  pay() {
    const bill = new EditableBill(this._bill);
    if (bill.recurrenceData.kind === 'Once') {
      bill.recurrenceData.endDate = bill.recurrenceData.date.subtract(1, 'day');
    } else {
      const today = immutableMoment().startOf('day');
      do {
        bill.date = getNextRecurringDate(bill.recurrenceData);
      } while (bill.date.diff(today, 'day') < 0);
    }
    this._dataStore.updateBill(bill);
  }

  delete() {
    this._dataStore.deleteBill(this._bill);
  }
}
