import * as React from 'react';
import * as MUI from '@material-ui/core';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef } from 'react';
import { ListRow, Row } from './ListRow';

export interface ListSection {
  title: string | undefined;
  numberOfRows: number;
}

export interface ListProps {
  className?: string;
  /**
   * The sections to display.
   */
  sections: ListSection[];

  /**
   * Render section's row
   * @param section The section's index
   * @param row The row's index in the section
   */
  renderItem: (section: number, row: number) => JSX.Element;
  /**
   * Render when there are no rows in all sections. Optional.
   */
  renderEmptyIndicator?: () => JSX.Element;
}

export const List: React.FunctionComponent<ListProps> = observer(
  ({ className, sections, renderEmptyIndicator, renderItem }) => {
    const numberOfRows = useMemo(() => getNumberOfRows(sections), [sections]);
    const listRef = useRef<VariableSizeList>(null);

    // Force refresh the list since otherwise the items size won't be updated
    useEffect(() => listRef.current?.resetAfterIndex(0));

    return numberOfRows === 0 ? (
      <MUI.Box
        flex={1}
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={className}
      >
        {renderEmptyIndicator?.()}
      </MUI.Box>
    ) : (
      <AutoSizer className={className} disableWidth>
        {(size) => (
          <VariableSizeList
            height={size.height}
            width={size.width}
            itemCount={numberOfRows}
            itemSize={(index) => getRowHeightAtIndex(index, sections)}
            ref={listRef}
          >
            {(props) => (
              <ListRow
                {...props}
                renderItem={renderItem}
                section={getSectionForIndex(props.index, sections)}
                row={getRowForIndex(props.index, sections)}
              />
            )}
          </VariableSizeList>
        )}
      </AutoSizer>
    );
  }
);

const getNumberOfRows = (sections: ListSection[]) => {
  let numberOfRows = 0;

  sections.forEach((section) => {
    if (section.title != null) {
      numberOfRows++;
    }

    numberOfRows += section.numberOfRows;
  });

  return numberOfRows;
};

const getRowHeightAtIndex = (index: number, sections: ListSection[]) => {
  const row = getRowForIndex(index, sections);

  // The values comes from inspecting the dom of the Material-UI page.
  // We normally shouldn't have to hardcode the height values, but react-window
  // needs to know the height of each row. So, we need to live with it for now ¯\_(ツ)_/¯.
  if (row == null) {
    return 0;
  } else if (row.index == null) {
    // Note: The real value is 48, but we want to reduce the bottom margin. Because the
    //       ListSubheader works with a line height, it's simpler to just have the next item
    //       overlap a little.
    return 36;
  } else {
    return 72;
  }
};

const getRowForIndex = (index: number, sections: ListSection[]): Row | undefined => {
  let row: Row | undefined;
  let previousRow = 0;
  let currentRow = 0;

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    // Header + elements of section + footer;
    if (section.title != null) {
      currentRow++;
    }

    currentRow += section.numberOfRows;

    // Row is in section.
    if (currentRow > index) {
      // Header row
      if (index === previousRow && section.title != null) {
        row = { section: i, index: undefined };
      } else {
        /* An element in section */
        let elementIndex = index - previousRow;

        // If section has a title, we need to remove it's index
        if (section.title != null) {
          elementIndex--;
        }

        row = { section: i, index: elementIndex };
      }

      if (row != null) {
        break;
      }
    }

    previousRow = currentRow;
  }

  return row;
};

const getSectionForIndex = (index: number, sections: ListSection[]): ListSection | undefined => {
  const row = getRowForIndex(index, sections);
  return row != null ? sections[row.section] : undefined;
};
