import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { observer } from 'mobx-react';
import { RouteTemplates } from '../../Routes';
import { useServices } from '../../hooks';

export interface AuthenticatedRouteProps extends RouteProps {}

export const AuthenticatedRoute: React.FunctionComponent<AuthenticatedRouteProps> = observer((props) => {
  const { location } = props;
  const { route, authentication } = useServices();

  if (authentication.user == null) {
    const loginLocation = {
      ...route.resolveLocation(RouteTemplates.login),
      state: { referrer: location }
    };
    return <Redirect to={loginLocation} />;
  }

  return <Route {...props} />;
});
