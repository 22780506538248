import * as React from 'react';
import { observer } from 'mobx-react';
import { AllBillsList, LateBillsList, UpcomingBillsList } from '../lists';
import * as MUI from '@material-ui/core';
import { AllBillListKind, BillListKind, DashboardViewModel } from '../../viewmodels';

export interface BillsProps {
  viewModel: DashboardViewModel;
  className?: string;
}

export const Bills: React.FunctionComponent<BillsProps> = observer(({ className, viewModel }) => {
  const classes = useStyles();

  return (
    <MUI.Grid className={`${classes.container} ${className}`} container spacing={2}>
      {/* Mobile */}
      <MUI.Hidden smUp>
        <MUI.Box height={'100%'} width="100%" mr={1} ml={1}>
          <MUI.Box clone height="100%">
            {getCurrentList(viewModel.currentListKind, viewModel)}
          </MUI.Box>
        </MUI.Box>
      </MUI.Hidden>

      {/* Desktop */}
      <MUI.Hidden xsDown>
        {AllBillListKind.map((listKind) => (
          <MUI.Grid item xs={12} sm={6} md={4} lg={4} className={classes.section} key={`list-${listKind}`}>
            <MUI.Box clone height="100%">
              {getCurrentList(listKind, viewModel)}
            </MUI.Box>
          </MUI.Grid>
        ))}
      </MUI.Hidden>
    </MUI.Grid>
  );
});

function getCurrentList(listKind: BillListKind, viewModel: DashboardViewModel) {
  switch (listKind) {
    case 'all':
      return <AllBillsList viewModel={viewModel.allBillsViewModel} />;
    case 'upcoming':
      return <UpcomingBillsList viewModel={viewModel.upcomingBillsViewModel} />;
    case 'late':
      return <LateBillsList viewModel={viewModel.lateBillsViewModel} />;
  }
}

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    container: {
      height: '100%',
      overflowY: 'auto'
    },
    section: {
      height: '100%'
    }
  })
);
