import * as React from 'react';
import { observer } from 'mobx-react';
import { useServices, useViewModel } from '../../hooks';
import { useEffect } from 'react';

export interface LoginProps {}

export const Login: React.FunctionComponent<LoginProps> = observer((props) => {
  const { viewModels } = useServices();
  const viewModel = useViewModel(() => viewModels!.createLoginViewModel());

  useEffect(
    () => {
      viewModel.login();
    },
    // eslint-disable-next-line
    []
  );

  return null;
});
