import { computed, makeObservable, observable } from 'mobx';
import { AllBillsViewModel, LateBillsViewModel, UpcomingBillsViewModel } from '../lists';
import { DataStore, LocalizationService, NavigationService } from '../../../services';

export type BillListKind = 'all' | 'upcoming' | 'late';
export const AllBillListKind: BillListKind[] = ['late', 'upcoming', 'all'];

export class DashboardViewModel {
  readonly allBillsViewModel: AllBillsViewModel;
  readonly upcomingBillsViewModel: UpcomingBillsViewModel;
  readonly lateBillsViewModel: LateBillsViewModel;

  @observable private _currentListKind: BillListKind = 'late';

  constructor(dataStore: DataStore, localization: LocalizationService, navigation: NavigationService) {
    makeObservable(this);

    this.allBillsViewModel = new AllBillsViewModel(dataStore, navigation, localization);
    this.upcomingBillsViewModel = new UpcomingBillsViewModel(dataStore, navigation, localization);
    this.lateBillsViewModel = new LateBillsViewModel(dataStore, navigation, localization);

    if (this.lateBillsViewModel.items.length === 0) {
      this._currentListKind = 'upcoming';
    }
  }

  @computed
  get currentListKind(): BillListKind {
    return this._currentListKind;
  }

  set currentListKind(value: BillListKind) {
    this._currentListKind = value;
  }

  @computed
  get currentListIndex(): number {
    return AllBillListKind.indexOf(this.currentListKind);
  }

  set currentListIndex(value: number) {
    this._currentListKind = AllBillListKind[value];
  }

  @computed
  get allBillsBadgeCount(): number {
    return this.allBillsViewModel.items.filter((item) => item.isExpired).length;
  }

  @computed
  get upcomingBillsBadgeCount(): number {
    return this.upcomingBillsViewModel.items.filter((item) => item.isToday).length;
  }

  @computed
  get lateBillsBadgeCount(): number {
    return this.lateBillsViewModel.items.length;
  }
}
