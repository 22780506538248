import * as React from 'react';
import { EditableListGrouping } from '../../models';
import * as MUI from '@material-ui/core';
import { useServices } from '../../hooks';
import { observer } from 'mobx-react';
import { AllBillListKind } from '../../viewmodels';

export interface ListGroupingFieldProps {
  viewModel: EditableListGrouping;
  className?: string;
}

export const ListGroupingField: React.FunctionComponent<ListGroupingFieldProps> = observer(
  ({ viewModel, className }) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.authenticated.settings;

    return (
      <MUI.FormControl className={className}>
        <MUI.FormLabel focused={false}>{strings.groupingGroupLabel}</MUI.FormLabel>
        <MUI.FormGroup>
          {AllBillListKind.map((kind) => (
            <MUI.FormControlLabel
              key={kind}
              label={strings.listLabel(kind)}
              control={
                <MUI.Checkbox checked={viewModel[kind]} onChange={(event, checked) => (viewModel[kind] = checked)} />
              }
            />
          ))}
        </MUI.FormGroup>
      </MUI.FormControl>
    );
  }
);
