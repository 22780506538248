import React from 'react';
import { AuthenticatedRoute, SplashScreen } from './components';
import { Redirect, Route, Router, Switch } from 'react-router';
import { observer } from 'mobx-react';
import { RouteTemplates } from './Routes';
import { Login, Logout, Dashboard, NotFound, Loading } from './views';
import { Alert } from '@material-ui/lab';
import * as MUI from '@material-ui/core';
import { useServices } from './hooks';

export interface AppProps {}

export const App: React.FunctionComponent<AppProps> = observer(() => {
  const { dataStore, authentication, theme, history, localization } = useServices();
  const strings = localization.localizedStrings.onboarding;
  const isLoading = authentication.isLoading || dataStore.isLoading;

  document.title = strings.tabTitle;

  return (
    <MUI.ThemeProvider theme={theme.muiTheme}>
      <MUI.CssBaseline />
      <Router history={history}>
        <SplashScreen isHidden={!isLoading}>
          <Loading />
        </SplashScreen>
        {!isLoading && (
          <Switch>
            <Route path={RouteTemplates.login} component={Login} />
            <Route path={RouteTemplates.logout} component={Logout} />
            <AuthenticatedRoute path={RouteTemplates.dashboard} component={Dashboard} />
            <AuthenticatedRoute
              path={RouteTemplates.landing}
              exact
              render={() => <Redirect to={RouteTemplates.dashboard} />}
            />
            <Route component={NotFound} />
          </Switch>
        )}
      </Router>
      {/* Error message */}
      <MUI.Snackbar
        open={dataStore.errorMessage != null}
        onClose={() => (dataStore.errorMessage = undefined)}
        autoHideDuration={5000}
      >
        <Alert severity={'error'} onClose={() => (dataStore.errorMessage = undefined)}>
          {dataStore.errorMessage}
        </Alert>
      </MUI.Snackbar>
    </MUI.ThemeProvider>
  );
});
