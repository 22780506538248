import { EditableModel } from './EditableModel';
import { Settings } from '../Settings';
import { EditableListGrouping } from './EditableListGrouping';
import { computed } from 'mobx';
import { SettingsContract } from '../api';

export class EditableSettings extends EditableModel<Settings> implements Settings {
  private readonly _grouping: EditableListGrouping;

  constructor(private readonly _settings: Settings | undefined) {
    super(_settings == null);

    this.setFields([], [(this._grouping = new EditableListGrouping(_settings?.grouping))]);
  }

  @computed
  get grouping(): EditableListGrouping {
    return this._grouping;
  }

  @computed
  get asContract(): SettingsContract {
    return {
      grouping: {
        all: this.grouping.all,
        late: this.grouping.late,
        upcoming: this.grouping.upcoming
      }
    };
  }
}
