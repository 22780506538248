import { ListGrouping, SettingsContract } from './api';

export interface Settings {
  readonly grouping?: ListGrouping;

  readonly asContract: SettingsContract;
}

export class AppSettings implements Settings {
  constructor(private readonly _settings: SettingsContract) {}

  get grouping(): ListGrouping | undefined {
    return this._settings.grouping;
  }

  get asContract(): SettingsContract {
    return this._settings;
  }
}
