import * as React from 'react';
import { AppBarViewModel } from '../../viewmodels';
import { observer } from 'mobx-react';
import ExitIcon from '@material-ui/icons/ExitToApp';
import * as MUI from '@material-ui/core';
import { useServices } from '../../hooks';
import SettingsIcon from '@material-ui/icons/SettingsRounded';

export interface ProfileMenuProps {
  viewModel: AppBarViewModel;
  className?: string;
}

export const ProfileMenu: React.FunctionComponent<ProfileMenuProps> = observer(({ className, viewModel }) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.authenticated.main.profileMenu;
  const classes = useStyles();

  return (
    <MUI.SwipeableDrawer
      className={className}
      anchor="right"
      open={viewModel.isProfileMenuOpen}
      onClose={() => viewModel.toggleProfileMenu()}
      onOpen={() => viewModel.toggleProfileMenu()}
    >
      <MUI.List className={classes.list}>
        {/* Avatar */}
        <MUI.ListItem>
          <MUI.ListItemAvatar>
            <MUI.Avatar src={viewModel.imageUrl} alt="Profile" />
          </MUI.ListItemAvatar>
          <MUI.ListItemText primary={viewModel.fullName} secondary={viewModel.email} />
        </MUI.ListItem>

        <MUI.Divider />

        {/* Settings */}
        <MUI.ListItem button onClick={() => viewModel.editSettings()}>
          <MUI.ListItemIcon>
            <SettingsIcon />
          </MUI.ListItemIcon>
          <MUI.ListItemText>{strings.settings}</MUI.ListItemText>
        </MUI.ListItem>

        {/* Logout */}
        <MUI.ListItem button onClick={() => viewModel.logout()}>
          <MUI.ListItemIcon>
            <ExitIcon />
          </MUI.ListItemIcon>
          <MUI.ListItemText>{strings.logout}</MUI.ListItemText>
        </MUI.ListItem>
      </MUI.List>
    </MUI.SwipeableDrawer>
  );
});

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    list: {
      width: 320,

      [theme.breakpoints.up('sm')]: {
        width: 420
      }
    }
  })
);
