import { AuthenticationService, DataStore, NavigationService } from '../../../services';
import { action, computed, observable, makeObservable, runInAction } from 'mobx';

export class AppBarViewModel {
  @observable isProfileMenuOpen: boolean = false;

  constructor(
    private readonly _dataStore: DataStore,
    private readonly _authentication: AuthenticationService,
    private readonly _navigation: NavigationService
  ) {
    makeObservable(this);
  }

  @computed
  get imageUrl(): string {
    return this._authentication.user?.photoURL ?? '';
  }

  @computed
  get fullName(): string {
    return this._authentication.user?.displayName ?? '';
  }

  @computed
  get email(): string {
    return this._authentication.user?.email ?? '';
  }

  set searchFilter(value: string | undefined) {
    runInAction(() => (this._dataStore.searchFilter = value));
  }

  @computed
  get noBills(): boolean {
    return this._dataStore.bills.length === 0 && this._dataStore.searchFilter?.length === 0;
  }

  @action
  toggleProfileMenu() {
    this.isProfileMenuOpen = !this.isProfileMenuOpen;
  }

  addBill() {
    this._navigation.navigateToBillEdit();
  }

  @action
  async logout() {
    this.isProfileMenuOpen = false;
    this._navigation.navigateToLogout();
    await this._authentication.logout();
  }

  @action
  editSettings() {
    this.isProfileMenuOpen = false;
    this._navigation.navigateToSettings();
  }
}
