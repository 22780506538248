import { computed, makeObservable } from 'mobx';
import { AppBillSectionViewModel, BillSectionViewModel } from './BillSectionViewModel';
import { groupBy } from 'lodash';
import { BillItemViewModel } from './BillItemViewModel';
import { LocalizationService } from '../../../services';

export abstract class BillListViewModel {
  protected constructor(protected readonly _localization: LocalizationService) {
    makeObservable(this);
  }

  @computed
  get sections(): BillSectionViewModel[] {
    if (!this.groupByCategory) {
      return [
        {
          title: undefined,
          numberOfRows: this.items.length,
          items: this.items
        }
      ];
    }

    const groupedByCategory = groupBy(this.items, 'category');
    return Object.keys(groupedByCategory).map(
      (key) => new AppBillSectionViewModel(groupedByCategory[key], this._localization)
    );
  }

  abstract get items(): BillItemViewModel[];
  abstract get groupByCategory(): boolean;
}
