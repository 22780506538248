import { EditableModel } from './EditableModel';
import { Period } from '../Period';
import { EditableProperty } from './EditableProperty';
import { immutableMoment, ImmutableMoment } from '../../utils';
import { unitOfTime } from 'moment';
import { computed, makeObservable } from 'mobx';
import { PeriodContract } from '../api';

export class EditablePeriod extends EditableModel<Period> implements Period {
  private _start: EditableProperty<ImmutableMoment, Period>;
  private _end: EditableProperty<ImmutableMoment, Period>;

  constructor(private readonly _period: Period | undefined) {
    super(_period == null);
    makeObservable(this);
    this.setFields([
      (this._start = new EditableProperty<ImmutableMoment, Period>(
        _period?.start ?? immutableMoment(),
        (value1, value2) => this.areSameDates(value1, value2, 'day')
      )),
      (this._end = new EditableProperty<ImmutableMoment, Period>(_period?.end ?? immutableMoment(), (value1, value2) =>
        this.areSameDates(value1, value2, 'day')
      ))
    ]);
  }

  @computed
  get start(): ImmutableMoment {
    return this._start.value!;
  }

  set start(value: ImmutableMoment) {
    this._start.value = value;
  }

  @computed
  get end(): ImmutableMoment {
    return this._end.value!;
  }

  set end(value: ImmutableMoment) {
    this._end.value = value;
  }

  @computed
  get isActive(): boolean {
    return immutableMoment().isBetween(this.start, this.end, 'day', '[]');
  }

  @computed
  get asContract(): PeriodContract {
    return {
      start: this.start.toISOString(),
      end: this.end.toISOString()
    };
  }

  private areSameDates = (
    date1?: ImmutableMoment,
    date2?: ImmutableMoment,
    granularity?: unitOfTime.StartOf
  ): boolean => {
    if (date1 == null && date2 == null) {
      return true;
    } else if (date1 == null || date2 == null) {
      return false;
    }
    return date1.isSame(date2, granularity);
  };
}
