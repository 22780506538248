import * as React from 'react';
import { useServices } from '../../hooks';
import { Redirect } from 'react-router';

export interface LogoutProps {}

export const Logout: React.FunctionComponent<LogoutProps> = () => {
  const { authentication } = useServices();

  authentication.logout();

  return <Redirect to={'/'} />;
};
