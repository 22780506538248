export interface AppBarStrings {
  readonly addBill: string;
  readonly addBillIndicator: string;
  readonly search: string;
  readonly shortAddBillIndicator: string;
  readonly title: string;
}

export const EnglishAppBarStrings: AppBarStrings = {
  addBill: 'Add bill',
  addBillIndicator: 'Start using the app by adding your first bill',
  search: 'Search…',
  shortAddBillIndicator: 'Add your first bill',
  title: 'Bills Manager'
};
