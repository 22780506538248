import { EnglishDateStrings } from '../../utils/DateStrings';
import { immutableMoment, ImmutableMoment } from '../../../../utils';

export interface PeriodStrings {
  readonly addPeriod: string;
  readonly cancel: string;
  readonly datePickerFormat: string;
  readonly dialogTitle: string;
  readonly end: string;
  readonly inactivePeriod: string;
  readonly noPeriod: string;
  readonly save: string;
  readonly start: string;

  dateRange(start: ImmutableMoment, end: ImmutableMoment): string;
  inactivePeriodInfo(start: ImmutableMoment, end: ImmutableMoment): string;
  remainingDays(days: number): string;
}

export const EnglishPeriodStrings: PeriodStrings = {
  addPeriod: 'Set period',
  cancel: 'Cancel',
  datePickerFormat: EnglishDateStrings.numericDateFormat,
  dialogTitle: 'Set period',
  end: 'End date',
  inactivePeriod: 'Inactive period',
  noPeriod: 'There is no period! Set a period to use the application at its fullest.',
  save: 'Save',
  start: 'Start date',
  dateRange: (start: ImmutableMoment, end: ImmutableMoment): string => {
    return `${EnglishDateStrings.dateFormat(start)} to ${EnglishDateStrings.dateFormat(end)}`;
  },
  inactivePeriodInfo: (start, end) => {
    const isPast = end.isBefore(immutableMoment(), 'day');
    return isPast
      ? `The period ended ${EnglishDateStrings.humanize(
          end
        ).toLowerCase()}. Set a new period to see your upcoming bills.`
      : `The period will start ${EnglishDateStrings.humanize(
          start
        ).toLowerCase()}. All the bills that are due before that date will be marked as late.`;
  },
  remainingDays: (days: number): string => {
    const dayStr = Math.abs(days) === 1 ? 'day' : 'days';
    return days < 0 ? `${days} ${dayStr} ago` : `${days} ${dayStr} remaining`;
  }
};
