export function onWheelStop(): Promise<void> {
  let isScrolling: NodeJS.Timeout;

  return new Promise<void>((resolve) => {
    window.addEventListener(
      'wheel',
      function () {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(function () {
          resolve();
        }, 66);
      },
      false
    );
  });
}
