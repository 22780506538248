import * as React from 'react';
import { Period, AppBar, Bills, Tabs } from '../../components';
import * as MUI from '@material-ui/core';
import { useServices, useViewModel } from '../../hooks';

export interface DashboardProps {
  className?: string;
}

export const Dashboard: React.FunctionComponent<DashboardProps> = ({ className }) => {
  const classes = useStyles();
  const { viewModels } = useServices();
  const viewModel = useViewModel(() => viewModels.createDashboardViewModel());

  return (
    <MUI.Box className={className} height={'100%'}>
      <AppBar
        renderTabs={() => (
          <MUI.Hidden smUp>
            <Tabs viewModel={viewModel} />
          </MUI.Hidden>
        )}
      />
      <MUI.Box className={classes.content}>
        <Period />
        <Bills viewModel={viewModel} className={classes.bills} />
      </MUI.Box>
    </MUI.Box>
  );
};

const appBarHeight = 64;
const mobileAppBarHeight = appBarHeight + 48;
const periodHeight = 136;

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    content: {
      padding: theme.spacing(2),
      height: `calc(100% - ${appBarHeight}px - ${periodHeight}px - ${theme.spacing(2)}px)`,

      [theme.breakpoints.down('xs')]: {
        height: `calc(100% - ${mobileAppBarHeight}px - ${periodHeight}px - ${theme.spacing(2)}px)`
      }
    },
    bills: {
      marginTop: theme.spacing(2)
    }
  })
);
