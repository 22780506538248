import { DataStore, NavigationService } from '../../../services';
import { action, computed, makeObservable } from 'mobx';
import { Period } from '../../../models';
import { immutableMoment, ImmutableMoment } from '../../../utils';

export class PeriodViewModel {
  constructor(private _dataStore: DataStore, private _navigation: NavigationService) {
    makeObservable(this);
  }

  get start(): ImmutableMoment | undefined {
    return this.period?.start;
  }

  get end(): ImmutableMoment | undefined {
    return this.period?.end;
  }

  @computed
  get remainingDays(): number {
    return this.period!.end.endOf('day').diff(immutableMoment().startOf('day'), 'day');
  }

  @computed
  get hasPeriod(): boolean {
    return this.period != null;
  }

  @computed
  get isActive(): boolean {
    return this.period?.isActive ?? false;
  }

  @action
  async editPeriod() {
    await this._navigation.navigateToPeriodEdit(this.period);
  }

  private get period(): Period | undefined {
    return this._dataStore.period;
  }
}
