import * as React from 'react';
import { observer } from 'mobx-react';
import { ProfileMenu } from './ProfileMenu';
import { useServices, useViewModel } from '../../hooks';
import * as MUI from '@material-ui/core';
import { Popper, SearchBar } from '../utils';
import { debounce } from 'lodash';
import AddBillIcon from '@material-ui/icons/AddCircleRounded';
import { AddBillIndicator } from './AddBillIndicator';

export interface AppBarProps {
  className?: string;

  renderTabs?: () => React.ReactNode;
}

export const AppBar: React.FunctionComponent<AppBarProps> = observer(({ className, renderTabs }) => {
  const { viewModels, localization } = useServices();
  const viewModel = useViewModel(() => viewModels!.createAppBarViewModel());
  const strings = localization.localizedStrings.authenticated.main.appBar;
  const classes = useStyles();

  const onSearch = (value: string | undefined) => {
    viewModel.searchFilter = value;
  };

  return (
    <MUI.AppBar className={className} position={'relative'}>
      <MUI.Toolbar>
        <MUI.Box flexGrow={1} mr={1}>
          <MUI.Typography variant={'h6'} noWrap>
            {strings.title}
          </MUI.Typography>
        </MUI.Box>

        <SearchBar placeholder={strings.search} onSearch={debounce(onSearch, 500)} />
        <Popper open={viewModel.noBills} placement={'bottom-end'} renderPopper={() => <AddBillIndicator />}>
          <MUI.Tooltip title={strings.addBill}>
            <MUI.IconButton className={classes.addBill} onClick={() => viewModel.addBill()}>
              <AddBillIcon />
            </MUI.IconButton>
          </MUI.Tooltip>
        </Popper>
        <MUI.IconButton onClick={() => viewModel.toggleProfileMenu()}>
          <MUI.Avatar src={viewModel.imageUrl} />
        </MUI.IconButton>
      </MUI.Toolbar>
      <ProfileMenu viewModel={viewModel} />
      {renderTabs?.()}
    </MUI.AppBar>
  );
});

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    addBill: {
      marginLeft: theme.spacing(1),
      color: theme.palette.primary.contrastText
    }
  })
);
