import * as React from 'react';
import { ListChildComponentProps } from 'react-window';
import { Observer } from 'mobx-react';
import * as MUI from '@material-ui/core';
import { ListSection } from './List';

export interface Row {
  section: number;
  index: number | undefined;
}

export interface ListRowProps extends ListChildComponentProps {
  /**
   * The row to render.
   */
  row: Row | undefined;
  /**
   * The row's section.
   */
  section: ListSection | undefined;

  /**
   * Render section's row
   * @param section The section's index
   * @param row The row's index in the section
   */
  renderItem: (section: number, row: number) => JSX.Element;
}

export const ListRow: React.FunctionComponent<ListRowProps> = ({ row, section, renderItem, style }) => {
  if (row?.index != null) {
    return (
      <Observer>
        {() => (
          // @ts-ignore
          <MUI.Box style={style}>
            {renderItem(row.section, row!.index!)}
            {/* Not displaying divider for last item in section */}
            {row!.index! < section!.numberOfRows - 1 && <MUI.Divider variant="inset" />}
          </MUI.Box>
        )}
      </Observer>
    );
  } else if (row != null) {
    return (
      // @ts-ignore
      <MUI.Box style={style}>
        <MUI.ListSubheader>{section!.title}</MUI.ListSubheader>
      </MUI.Box>
    );
  }

  console.warn("Rendering a row which doesn't exist in the data.");
  return null;
};
