import * as VM from '../viewmodels';
import { ServiceProvider } from './ServiceProvider';
import { Period } from '../models';

export class ViewModelProvider {
  constructor(private _services: ServiceProvider) {}
  createLoginViewModel(): VM.LoginViewModel {
    return new VM.LoginViewModel(this._services.authentication, this._services.navigation);
  }

  createPeriodViewModel(): VM.PeriodViewModel {
    return new VM.PeriodViewModel(this._services.dataStore, this._services.navigation);
  }

  createPeriodEditViewModel(
    period: Period | undefined,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.PeriodEditViewModel {
    return new VM.PeriodEditViewModel(this._services.dataStore, period, onSuccess, onCancel);
  }

  createBillEditViewModel(
    billId: string | undefined,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.BillEditViewModel {
    return new VM.BillEditViewModel(this._services.dataStore, billId, onSuccess, onCancel);
  }

  createAppBarViewModel(): VM.AppBarViewModel {
    return new VM.AppBarViewModel(this._services.dataStore, this._services.authentication, this._services.navigation);
  }

  createDashboardViewModel(): VM.DashboardViewModel {
    return new VM.DashboardViewModel(this._services.dataStore, this._services.localization, this._services.navigation);
  }

  createSettingsViewModel(onSuccess: () => void, onCancel: () => void): VM.SettingsViewModel {
    return new VM.SettingsViewModel(this._services.dataStore, onSuccess, onCancel);
  }
}
